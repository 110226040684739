import React, { useContext } from 'react'
import styled from 'styled-components'
import SummaryCard from './SummaryCard'
import Achievements from './Achievements'
import ActivityChart from './ActivityChart'
import PointTable from './PointTable'
import GuestWidget from './GuestWidget'
import { UserContext } from '../../contexts'
import TopicProgress from './TopicProgress'

const HeaderBar = styled.div`
  display: flex;
  height: 128px;
  justify-content: center;
  
  @media only screen and (max-width: 768px) {
    height: auto;
  }
`

const HeaderContent = styled.div`
  align-items: center;
  /* background-color: white; */
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  height: auto;
  max-width: 1260px;
  padding: 15px;
  width: 90%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  h2 {
    color: #333;
    font-family: Source Serif Pro;
    font-size: 2em;
    font-weight: bold;
    margin: 0;
    line-height: 1em;
    white-space: nowrap;

    @media only screen and (max-width: 768px) {
      font-size: 1.25em;
    }
  }
`

const Content = styled.div`
  margin: auto;
  max-width: 1260px;
  width: 90%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const Stats = () => {
  const { user } = useContext(UserContext)
  if (!user) {
    return <GuestWidget />
  }

  return (
    <>
      <HeaderBar>
        <HeaderContent>
          <h2>My Stats</h2>
        </HeaderContent>
      </HeaderBar>
      <Content>
        <SummaryCard user={user} />
        <ActivityChart user={user} />
        <TopicProgress user={user} />
        <Achievements user={user} />
        <PointTable user={user} />
      </Content>
    </>
  )
}

export default Stats