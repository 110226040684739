import React, { useState, useEffect } from 'react'
import { BlockMath } from 'react-katex'
import { Button, Dropdown, Menu, message } from 'antd'
import PropTypes from 'prop-types'
import { DownOutlined } from '@ant-design/icons'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput'
import { Message } from '../../feedback'
import { generateProblem } from '../../engine'

const levels = [{ key: 2, label: "Two Terms", desc: "" }, { key: 3, label: "Three Terms" }]

const NegativeMultiTable = props => {
  const [difficulty, setDifficulty] = useState(2)
  const [problem, setProblem] = useState(generateProblem(props.key, { difficulty }))
  const [ans, setAns] = useState(undefined)
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");

  useEffect(() => {
    handleRefresh()
  }, [difficulty])

  const handleRefresh = () => {
    setProblem(generateProblem(props.key, { difficulty }))
    setAns(undefined)
    setAnsResult("default")
    setDisableAns(false)
    setDisableCheck(false)
  }

  const handleCheckAns = () => {
    if (ans === undefined) return;
    // eslint-disable-next-line 
    if (problem.evalAns(ans)) {
      props.addPoint(difficulty === 2 ? 1 : 2, props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  const handleShowAns = () => {
    setAns(problem.ans)
    setAnsResult("default")
    setDisableAns(true)
    setDisableCheck(true)
  }

  return (
    <ProblemBox
      title={"Negative Multiplication"}
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
      levels={levels} 
      difficulty={difficulty}
      setDifficulty={setDifficulty}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontSize: "1.75em" }}>
          {difficulty === 2
            ? <BlockMath>{`{${problem.a}}\\times{${problem.b}}=`}</BlockMath>
            : <BlockMath>{`{${problem.a}}\\times{${problem.b}}\\times{${problem.c}}=`}</BlockMath>
          }
        </div>
        <NumberInput
          disabled={disableAns}
          inputMode="numeric"
          onChange={val => {
            setAns(val)
            setAnsResult("default")
          }}
          result={ansResult}
          size="large"
          style={{ fontSize: "1.5em", margin: "0 10px 0 10px" }}
          value={ans}
        />
      </div>
    </ProblemBox>
  )
}

NegativeMultiTable.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

NegativeMultiTable.defaultProps = {
  addPoint: () => { },
  name: "Negative Multiplication",
  key: "negativeMultiplication"
}


export default NegativeMultiTable