import React, { useEffect, useState } from 'react'
import { BlockMath } from 'react-katex';
import PropTypes from 'prop-types'
import { message } from 'antd'
import { randomNum } from '../../util'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput';
import VerticalEquationFormat from '../_components/VerticalEquationFormat'
import { Message } from '../../feedback'
import { generateProblem } from '../../engine'

const levels = [
  { key: "m", label: "Medium", desc: "" },
  { key: "h", label: "Hard", desc: "Bigger numbers!" }
]

const MultiTable = props => {
  const [difficulty, setDifficulty] = useState("m")
  const [problem, setProblem] = useState(generateProblem(props.key, { difficulty }))
  const [ans, setAns] = useState()
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");
  const [isVertical, setIsVertical] = useState(randomNum(0, 1))

  useEffect(() => {
    handleRefresh()
  }, [difficulty])

  const handleRefresh = () => {
    setProblem(generateProblem(props.key, { difficulty }))
    setAns(undefined)
    setAnsResult("default")
    setDisableAns(false)
    setDisableCheck(false)
    setIsVertical(randomNum(0, 1))
  }

  const handleCheckAns = () => {
    if (ans === undefined) return;
    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint((difficulty === "m" ? 1 : 2), props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  const handleShowAns = () => {
    setAns(problem.ans)
    setAnsResult("default")
    setDisableAns(true)
    setDisableCheck(true)
  }

  const renderAnsInput = () => (
    <NumberInput
      disabled={disableAns}
      inputMode="numeric"
      onChange={val => {
        setAns(val)
        setAnsResult("default")
      }}
      result={ansResult}
      size="large"
      value={ans}
    />
  )

  return (
    <ProblemBox
      title={props.name}
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
      levels={levels}
      difficulty={difficulty}
      setDifficulty={setDifficulty}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {isVertical
          ? (
            <div>
              <VerticalEquationFormat a={problem.a} b={problem.b} op="*" />
              {renderAnsInput()}
            </div>
          )
          : (
            <>
              <div style={{ fontSize: "1.75em" }}>
                <BlockMath>{`${problem.a}\\times${problem.b}=`}</BlockMath>
              </div>
              {renderAnsInput()}
            </>
          )}
      </div>
    </ProblemBox>
  )
}

MultiTable.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

MultiTable.defaultProps = {
  addPoint: () => { },
  name: "Multiplication",
  key: "multiplication",
}

export default MultiTable