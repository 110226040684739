import React from 'react'
import { Route } from 'react-router-dom'
import ExercisePage from '.'
import {
  Addition,
  ExpandedFormAddition,
  Mean,
  Median,
  Mode,
  Range,
  AddDecimal,
  DivisionTable,
  DivisionWordProblem,
  LongDivision,
  Exponents,
  ExpandedExponents,
  AddFractions,
  DivideFractions,
  MultiplyFractions,
  CompareFractions,
  FractionToDecimal,
  FractionToPercentage,
  ConvertImpFractions,
  ConvertMixedNumber,
  AddMoney,
  CountingMoney,
  Multiplication,
  MultiWordProblem,
  NegativeAddition,
  NegativeSubtraction,
  NegativeMultiTable,
  NegativeDivisionTable,
  ConvertPercentage,
  ValuePercentage,
  RatioWordProblem,
  Subtraction,
  AddTime,
  OrderIntegers,
  RealLifeIntegers,
  TextToStandard,
  SquareRoots,
  EvaluateRadical,
  AddingRadicals,
} from '../../exercise'

/**
 * Map structure:
 * 
 * [TopicKey]: {
 *   exercises: { exerciseKey: Array<TopicListItem> }
 *   exerciseListItem: [{ key: exerciseKey, label: string }]
 *   selectedExercise: [exerciseKey]
 *   title: string
 * }
 * 
 */

// TODO: Refactor out addPoint into a context or HOC
const exerciseRoutes = (addPoint) => {
  const config = [
    {
      exercises: {
        addition: <Addition addPoint={addPoint} />,
        expandedFormAddition: <ExpandedFormAddition addPoint={addPoint} />
      },
      exerciseListItems: [
        { key: 'addition', label: 'Addition' },
        { key: 'expandedFormAddition', label: 'Expanded Form Addition' }
      ],
      topicKey: "addition",
      path: "addition",
      selectedExercise: "addition",
      title: "Addition"
    },
    {
      exercises: {
        subtraction: <Subtraction addPoint={addPoint} />,
      },
      selectedExercise: "subtraction",
      topicKey: "subtraction",
      path: "subtraction",
      title: "Subtraction"
    },
    {
      exercises: {
        multiplication: <Multiplication addPoint={addPoint} />,
        multiplicationWordProblem: <MultiWordProblem addPoint={addPoint} />
      },
      exerciseListItems: [
        { key: 'multiplication', label: 'Multiplication' },
        { key: 'multiplicationWordProblem', label: 'Multiplication Word Problems' }
      ],
      topicKey: "multiplication",
      selectedExercise: "multiplication",
      path: "multiplication",
      title: "Multiplication"
    },
    {
      exercises: {
        division: <DivisionTable addPoint={addPoint} />,
        longDivision: <LongDivision addPoint={addPoint} />,
        divisionWordProblem: <DivisionWordProblem addPoint={addPoint} />,
      },
      exerciseListItems: [
        { key: 'division', label: 'Division' },
        { key: 'longDivision', label: 'Long Division' },
        { key: 'divisionWordProblem', label: 'Division Word Problems' }
      ],
      topicKey: "division",
      selectedExercise: "division",
      path: "division",
      title: "Division"
    },
    {
      exercises: {
        realLifeIntegers: <RealLifeIntegers addPoint={addPoint} />,
        orderIntegers: <OrderIntegers addPoint={addPoint} />
      },
      exerciseListItems: [
        { key: 'realLifeIntegers', label: 'Real Life Integers' },
        { key: 'orderIntegers', label: 'Order Integers' }
      ],
      topicKey: "understandingNegatives",
      selectedExercise: "realLifeIntegers",
      path: "understanding-negatives",
      title: "Understanding Negatives"
    },
    {
      exercises: {
        negativeAddition: <NegativeAddition addPoint={addPoint} />,
      },
      topicKey: "negativeAddition",
      selectedExercise: "negativeAddition",
      path: "negative-addition",
      title: "Negative Addition"
    },
    {
      exercises: {
        negativeSubtraction: <NegativeSubtraction addPoint={addPoint} />,
      },
      topicKey: "negativeSubtraction",
      selectedExercise: "negativeSubtraction",
      path: "negative-subtraction",
      title: "Negative Subtraction"
    },
    {
      exercises: {
        negativeMultiplication: <NegativeMultiTable addPoint={addPoint} />,
      },
      topicKey: "negativeMultiplication",
      selectedExercise: "negativeMultiplication",
      path: "negative-multiplication",
      title: "Negative Multiplication"
    },
    {
      exercises: {
        ratioWordProblem: <RatioWordProblem addPoint={addPoint} />,
      },
      topicKey: "ratios",
      selectedExercise: "ratioWordProblem",
      path: "ratios",
      title: "Ratios"
    },
    {
      exercises: {
        negativeDivision: <NegativeDivisionTable addPoint={addPoint} />,
      },
      topicKey: "negativeDivision",
      selectedExercise: "negativeDivision",
      path: "negative-division",
      title: "Negative Division"
    },
    {
      exercises: {
        convertPercentage: <ConvertPercentage addPoint={addPoint} />,
        valuePercentage: <ValuePercentage addPoint={addPoint} />
      },
      exerciseListItems: [
        { key: 'convertPercentage', label: 'Convert Percentage' },
        { key: 'valuePercentage', label: 'Value Percentage' }
      ],
      topicKey: "percentages",
      selectedExercise: "convertPercentage",
      path: "percentages",
      title: "Percentages"
    },
    {
      exercises: {
        fractionDecimal: <FractionToDecimal addPoint={addPoint} />,
        fractionPercentage: <FractionToPercentage addPoint={addPoint} />,
        compareFractions: <CompareFractions addPoint={addPoint} />
      },
      exerciseListItems: [
        { key: 'fractionDecimal', label: 'Fraction To Decimal' },
        { key: 'fractionPercentage', label: 'Fraction To Percentage' },
        { key: 'compareFractions', label: 'Comparing Fractions' }
      ],
      topicKey: "fractions",
      selectedExercise: "fractionDecimal",
      path: "fractions",
      title: "Fraction"
    },
    {
      exercises: {
        convertImpFractions: <ConvertImpFractions addPoint={addPoint} />,
      },
      topicKey: "improperFractions",
      selectedExercise: "convertImpFractions",
      path: "improper-fractions",
      title: "Improper Fraction"
    },
    {
      exercises: {
        convertMixedNumber: <ConvertMixedNumber addPoint={addPoint} />,
      },
      topicKey: "mixedNumbers",
      selectedExercise: "convertMixedNumber",
      path: "mixed-numbers",
      title: "Mixed Number"
    },
    {
      exercises: {
        moneyAddition: <AddMoney addPoint={addPoint} />,
        countingMoney: <CountingMoney addPoint={addPoint} />
      },
      exerciseListItems: [
        { key: 'moneyAddition', label: 'Adding Money' },
        { key: 'countingMoney', label: 'Counting Money' }
      ],
      topicKey: "money",
      selectedExercise: "moneyAddition",
      path: "money",
      title: "Money"
    },
    {
      exercises: {
        addDecimal: <AddDecimal addPoint={addPoint} />,
      },
      topicKey: "decimalAddition",
      selectedExercise: "addDecimal",
      path: "decimal-addition",
      title: "Decimal Addition"
    },
    {
      exercises: {
        addFractions: <AddFractions addPoint={addPoint} />,
      },
      topicKey: "fractionAddition",
      selectedExercise: "addFractions",
      path: "fraction-addition",
      title: "Fraction Addition"
    },
    {
      exercises: {
        multiplyFractions: <MultiplyFractions addPoint={addPoint} />,
      },
      topicKey: "fractionMultiplication",
      selectedExercise: "multiplyFractions",
      path: "fraction-multiplication",
      title: "Fraction Multiplication"
    },
    {
      exercises: {
        divideFractions: <DivideFractions addPoint={addPoint} />,
      },
      topicKey: "fractionDivision",
      selectedExercise: "divideFractions",
      path: "fraction-division",
      title: "Fraction Division"
    },
    {
      exercises: {
        exponents: <Exponents addPoint={addPoint} />,
        expandedExponents: <ExpandedExponents addPoint={addPoint} />
      },
      exerciseListItems: [
        { key: 'exponents', label: 'Exponents' },
        { key: 'expandedExponents', label: 'Expanded Exponents' }
      ],
      topicKey: "exponents",
      selectedExercise: "exponents",
      path: "exponents",
      title: "Exponent"
    },
    {
      exercises: {
        mean: <Mean addPoint={addPoint} />,
        median: <Median addPoint={addPoint} />,
        mode: <Mode addPoint={addPoint} />,
        range: <Range addPoint={addPoint} />
      },
      exerciseListItems: [
        { key: 'mean', label: 'Mean' },
        { key: 'median', label: 'Median' },
        { key: 'mode', label: 'Mode' },
        { key: 'range', label: 'Range' }
      ],
      topicKey: "averages",
      selectedExercise: "mean",
      path: "averages",
      title: "Averages"
    },
    {
      exercises: {
        addTime: <AddTime addPoint={addPoint} />,
      },
      topicKey: "timeAddition",
      selectedExercise: "addTime",
      path: "time-addition",
      title: "Time Addition"
    },
    {
      exercises: {
        textToStandard: <TextToStandard addPoint={addPoint} />,
      },
      topicKey: "numbers",
      selectedExercise: "textToStandard",
      path: "written-numbers",
      title: "Written Numbers"
    },
    {
      exercises: {
        squareRoots: <SquareRoots addPoint={addPoint} />,
        evaluateRadical: <EvaluateRadical addPoint={addPoint} />,
        addingRadicals: <AddingRadicals addPoint={addPoint} />
      },
      exerciseListItems: [
        { key: 'squareRoots', label: 'Square Roots' },
        { key: 'evaluateRadical', label: 'Evaluating Radicals' },
        { key: 'addingRadicals', label: 'Adding Radicals' },
      ],
      topicKey: "radicals",
      selectedExercise: "squareRoots",
      path: "radicals",
      title: "Radicals"
    },
  ]

  return config.map(({ path, ...c }) => (
    <Route key={path} path={path} element={<ExercisePage {...c} />} />
  ))
}

export default exerciseRoutes
