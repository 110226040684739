import React, { useState } from "react"
import { BlockMath } from 'react-katex';
import styled from 'styled-components'
import { generateProblem } from "../engine"

const Debug = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  p {
    margin: 0px;
  }

  button { 
    margin: 10px;
  }
`

const exerciseKey = "addingRadicals"

const EngineSandbox = () => {
  const [problem, setProblem] = useState(generateProblem(exerciseKey))

  return (
    <div>
      <div style={{ fontSize: "1.75em", margin: 50 }}>
        <BlockMath>{`${problem.a}\\sqrt{${problem.r}} + ${problem.b}\\sqrt{${problem.r}} `}</BlockMath>
      </div>
      <Debug>
        <p>{JSON.stringify(problem)}</p>
        <button onClick={() => setProblem(generateProblem(exerciseKey))}>Refresh</button>
      </Debug>
    </div>
  )
}

export default EngineSandbox