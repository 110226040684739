import React from 'react'
import ScrollToTop from '../component/ScrollToTop'

const Contact = () => (
  <div>
    <ScrollToTop />
    <div style={{ borderBottom: "1px solid #e8e8e8", display: "flex", alignItems: "center", justifyContent: "center", height: 64 }}>
      <h2 style={{ color: "#333", fontFamily: "Source Serif Pro", fontWeight: "bold", margin: 0 }}>Contact</h2>
    </div>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "24px", fontSize: "1.2em" }}>
      <p>Contact form coming soon!</p>
      <p>For now contact the creator at <a href="mailto: csangprom@gmail.com" style={{ color: "#5C9B6A" }}>csangprom@gmail.com</a></p>
      {/* <form>
        <div>
        <label for="email">Email</label><br />
        <input required id="name" name="email" type="text" /><br/>
        <label for="subject">Subject</label><br />
        <input required id="subject" name="subject" type="text" /><br/>
        <label for="message">Message</label><br />
        <input required id="message" name="message" type="text" /><br/>
        </div>
      </form> */}
    </div>
  </div>
)

export default Contact