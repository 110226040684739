import React, { useEffect, useState } from 'react'
import { message, Button, Input, Modal } from 'antd'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import GoogleSignInButton from '../component/GoogleSignInButton'

const errMessage = {
  "auth/invalid-email": "Email format is incorrect",
  "auth/user-not-found": "Account does not exist",
  "auth/missing-email": "Please provide an email in the email field"
}

const LoginModal = props => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const auth = getAuth()
  const navigate = useNavigate()

  // Check if user is already logged in, redirect to dashboard
  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (props.showAuthModal && user) {
        props.setShowAuthModal(false)
        navigate("/app/topics")
      }
    })
  }, [props.showAuthModal])

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        props.setShowAuthModal(false)
        if (window.location.pathname === "/") navigate("/app/topics")
      })
      .catch(err => message.error(errMessage[err.code] || err.message))
  }

  const handleNeedAccount = () => {
    props.setShowAuthModal(false)
    navigate("register")
  }

  const handleForgotPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => message.info("Email sent!"))
      .catch(err => message.error(errMessage[err.code] || err.message))
  }

  const handleGoogleSignIn = () => {
    props.setShowAuthModal(false)
    if (window.location.pathname === "/") navigate("/app/topics")
  }

  return (
    <Modal
      closable={false}
      onCancel={() => props.setShowAuthModal(false)}
      footer={null}
      visible={props.showAuthModal}
    >
      <div style={{ backgroundColor: "#5C9B6A", borderRadius: 3, padding: 10, marginBottom: 10 }}>
        <h2 style={{ color: "white", fontFamily: "Source Serif Pro", fontSize: "1.5em", fontWeight: "bold", textAlign: "center", margin: 0 }}>MoolaMath</h2>
        <p style={{ color: "white", fontFamily: "Source Sans Pro", textAlign: "center", margin: 0 }}>The Math Problem Generator</p>
      </div>
      <div>
        <p style={{ margin: 0 }}>Email:</p>
        <Input autoFocus onChange={e => setEmail(e.target.value)} style={{ marginBottom: 10 }} value={email} />
        <p style={{ margin: 0 }}>Password:</p>
        <Input.Password onPressEnter={handleLogin} onChange={e => setPassword(e.target.value)} style={{ marginBottom: 10 }} value={password} />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <p onClick={handleNeedAccount} style={{ color: "#5C9B6A", cursor: "pointer", margin: 0, display: "flex", fontFamily: "Source Sans Pro", alignItems: "center" }}>Need an account?</p>
          <p onClick={handleForgotPassword} style={{ color: "#5C9B6A", cursor: "pointer", margin: 0, display: "flex", fontFamily: "Source Sans Pro", alignItems: "center" }}>Forgot password?</p>
        </div>
        <Button onClick={handleLogin} style={{ background: "#5FC776", border: "none", borderRadius: 3, fontFamily: "Source Sans Pro", marginTop: 20, width: "100%" }} type="primary">
          Sign In
        </Button>
        <div style={{ backgroundColor: "#e8e8e8", height: 1, width: "100%", margin: "20px 0px" }} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <GoogleSignInButton onSuccess={handleGoogleSignIn} />
        </div>
      </div>
    </Modal>
  )
}

export default LoginModal