import React, { useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import { BeatLoader } from 'react-spinners'
import { Tooltip } from 'antd'
import Refresh from '../../image/refresh'
import Eye from '../../image/eye'
import SettingsIcon from '../../image/control-panel'
import { UserContext } from '../../contexts'
import ProblemSettingsModal from './ProblemSettingsModal'
import { useEffect } from 'react'

const AnswerButton = styled.button`
  align-self: start;
  background-color: #5FC776;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  margin-top: 50px;
  outline: none;
  padding: 5px 15px;
  width: 120px;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s;
  }

  &:active {
    opacity: 0.7;
  }
`

const Container = styled.div`
  margin: 15px;
`

const OperationContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: end;
`

const ProblemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px 0px;
  min-height: 250px;
`

const ProblemBox = props => {
  const { activeSub } = useContext(UserContext)
  const [showDifficultyModal, setShowDifficultyModal] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const answerButton = useRef()

  useEffect(() => {
    const click = (e) => {
      if (e.key === "Enter") {
        answerButton.current.click()
      }
    }

    window.addEventListener("keydown", click)
    return function cleanup() {
      window.removeEventListener("keydown", click)
    }
  }, [])

  // Artificial UI delay for spinner animation
  const processAnswer = () => {
    setIsProcessing(true)
    setTimeout(() => {
      props.checkAnswer()
      setIsProcessing(false)
    }, 500)
  }

  return (
    <Container>
      <OperationContainer>
        <Tooltip title="New Problem" trigger={["hover", "click"]}>
          <Refresh
            className="iconButton"
            fill="#5FC776"
            height={20}
            onClick={props.refresh}
            style={{ marginRight: 20 }}
            width={20}
          />
        </Tooltip>
        {props.showAnswer && (
          <Tooltip title={activeSub ? "Show Answer" : "Upgrade to unlock Show Answer!"} trigger={["hover", "click"]}>
            <Eye
              className="iconButton"
              fill={activeSub ? "#5FC776" : "#e8e8e8"}
              height={20}
              onClick={activeSub && props.showAnswer}
              style={{ marginRight: 20 }}
              width={20}
            />
          </Tooltip>
        )}
        {props.levels && (
          <>
            <Tooltip title="Difficulty Settings" trigger={["hover", "click"]}>
              <SettingsIcon
                className="iconButton"
                fill="#5FC776"
                height={20}
                onClick={() => setShowDifficultyModal(true)}
                style={{ marginRight: 20 }}
                width={20}
              />
            </Tooltip>
            <ProblemSettingsModal
              levels={props.levels}
              onClose={() => setShowDifficultyModal(false)}
              selectedDifficulty={props.difficulty}
              setDifficulty={props.setDifficulty}
              showDifficultyModal={showDifficultyModal}
            />
          </>
        )}
      </OperationContainer>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <ProblemContainer>
          {props.text && <p style={{ fontFamily: "Source Serif Pro", fontSize: "1.25em", marginBottom: 20 }}>{props.text}</p>}
          {props.children}
          {props.checkAnswer && (
            <AnswerButton id="answer-button" ref={answerButton} onClick={props.disableCheck ? props.refresh : processAnswer}>
              {isProcessing
                ? <BeatLoader color="white" size={5} />
                : props.disableCheck ? "Next Problem" : "Check Answer"
              }
            </AnswerButton>
          )}
        </ProblemContainer>
      </div>
    </Container >
  )
}

export default ProblemBox