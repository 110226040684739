// TODO: Merge topicLabels and topicEndpoints

export const topicLabels = {
  addition: "Addition",
  subtraction: "Subtraction",
  multiplication: "Multiplication",
  division: "Division",
  places: "Places",
  rounding: "Roudning",
  numbers: "Written Numbers",
  factors: "Factors",
  primeNumbers: "Prime Numbers",
  ratios: "Ratios",
  rates: "Rates",
  percentages: "Percentages",
  unitConversion: "Unit Conversion",
  understandingNegatives: "Understanding Negatives",
  negativeAddition: "Negative Addition",
  negativeSubtraction: "Negative Subtraction",
  negativeMultiplication: "Negative Multiplication",
  negativeDivision: "Negative Division",
  fractions: "Fractions",
  improperFractions: "Improper Fractions",
  mixedNumbers: "Mixed Numbers",
  fractionAddition: "Fraction Addition",
  fractionSubtraction: "Fraction Subtraction",
  fractionMultiplication: "Fraction Multiplication",
  fractionDivision: "Fraction Division",
  decimalAddition: "Decimal Addition",
  money: "Money",
  absoluteValue: "Absolute Value",
  exponents: "Exponents",
  orderOfOperations: "Order of Operations",
  radicals: "Radicals",
  negativeExponents: "Negative Exponents",
  area: "Area",
  tables: "Tables",
  graphs: "Graphs",
  averages: "Averages",
  angles: "Angles",
  triangles: "Triangles",
  timeAddition: "Time Addition",
  usingVariables: "Using Variables",
  usingFormulas: "Using Formulas"
}

export const topicEndpoints = {
  addition: "addition",
  subtraction: "subtraction",
  multiplication: "multiplication",
  division: "division",
  places: "places",
  rounding: "rounding",
  numbers: "written-numbers",
  ratios: "ratios",
  rates: "rates",
  percentages: "percentages",
  unitConversion: "unit-conversion",
  understandingNegatives: "understanding-negatives",
  negativeAddition: "negative-addition",
  negativeSubtraction: "negative-subtraction",
  negativeMultiplication: "negative-multiplication",
  negativeDivision: "negative-division",
  fractions: "fractions",
  improperFractions: "improper-fractions",
  mixedNumbers: "mixed-numbers",
  fractionAddition: "fraction-addition",
  fractionSubtraction: "fraction-subtraction",
  fractionMultiplication: "fraction-multiplication",
  fractionDivision: "fraction-division",
  decimalAddition: "decimal-addition",
  money: "money",
  absoluteValue: "absolute-value",
  exponents: "exponents",
  orderOfOperations: "order-of-operations",
  radicals: "radicals",
  negativeExponents: "negative-exponents",
  area: "area",
  tables: "tables",
  graphs: "graphs",
  averages: "averages",
  angles: "angles",
  triangles: "triangles",
  timeAddition: "time-addition",
  usingVariables: "using-variables",
  usingFormulas: "using-formulas"
}