import React, { useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import { message } from 'antd'
import styled from 'styled-components'
import { BeatLoader } from 'react-spinners'
import { getApp } from '@firebase/app'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { getCurrentUserSubscriptions, getStripePayments } from '@stripe/firestore-stripe-payments'
import AppMobileHeader from './AppMobileHeader'
import { UserContext } from '../../contexts'
import { ReactComponent as _Logo } from '../../image/logo-light.svg'
import { ReactComponent as ChartIcon } from '../../image/bar-chart.svg'
import { ReactComponent as MathIcon } from '../../image/math-ops.svg'
import { ReactComponent as NoteIcon } from '../../image/note.svg'
import { ReactComponent as SettingIcon } from '../../image/gear.svg'
import { ReactComponent as LogoutIcon } from '../../image/logout.svg'
import { ReactComponent as AccountIcon } from '../../image/account.svg'
import GuestBanner from './GuestBanner'

// TODO: Refactor all svg icons
const LogoContainer = styled(Link)`
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: center;
  height: 64px;
  width: 64px;
`

export const Logo = styled(_Logo)`
  cursor: pointer;
  height: 40px;
  width: 40px;

  & path {
    fill: white;
  }

  & line {
    stroke: white;
  }

  &:hover line {
    stroke: #eee;
  }

  &:hover path {
    fill: #eee;
  }
`

const Container = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Sidebar = styled.div`
  background-color: #5C9B6A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow: auto;
  width: 80px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const NavLink = styled(Link)`
  background-color: ${props => props.active ? "#8dc38f" : "none"};
  border-radius: 5px;
  display: flex;
  font-family: Source Sans Pro;
  margin-top: 15px;
  padding: 15px;
  transition: 0.25s;

  &:hover { 
    background-color: #8dc38f;
  }

  & svg {
    cursor: pointer;
    fill: white;
  }
`

const SignInIcon = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  color: white;
  cursor: pointer;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  transition: 0.25s;
  white-space: nowrap;

  &:hover {
    background-color: #8dc38f;
  }

  & svg {
    fill: white;
  }
`

const Content = styled.div`
  height: 100vh;
  overflow: auto;
  padding-bottom: 200px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    height: 100%;
  }
  `

const Footer = styled.div`
  color: #b0b0b0;
  display: flex;
  font-family: Source Sans Pro;
  max-width: 1260px;
  padding: 15px;
  width: 90%;

  p {
    cursor: pointer;
    margin: 10px 50px 0px 0px;
  }
  
  p:hover {
    color: #5C9B6A;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

const Dashboard = (props) => {
  const [isGuest, setIsGuest] = useState(true)
  const [hasActiveSub, setHasActiveSub] = useState(true)
  const auth = getAuth()
  const location = useLocation();
  const app = useMemo(() => getApp(), [])
  const payments = useMemo(() => getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers"
  }), [app])


  useEffect(() => {
    onAuthStateChanged(auth, user => {
      async function getSubs() {
        await getCurrentUserSubscriptions(payments, { status: "active" })
          .then(res => setHasActiveSub(props.user?.isBetaTester || res.length > 0))
          .catch(err => console.log("getCurrentUserSubscriptions", err))
      }

      setIsGuest(!user)
      if (user) {
        getSubs()
      } else {
        setHasActiveSub(false)
      }
    })
  }, [])

  const handleSignout = () => {
    signOut(auth)
      .then(() => {
        props.setShowAuthModal(false)
        props.setUser()
        message.success("Successfully signed off")
      })
      .catch(err => console.log("log out error", err))
  }

  if (props.loading) {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
        <BeatLoader color="#5FC776" />
      </div>
    )
  }

  return (
    <Container>
      <Sidebar>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <LogoContainer to="/app/topics">
            <Logo />
          </LogoContainer>
          <NavLink active={location.pathname.includes("/app/topics") ? 1 : 0} to="/app/topics"><MathIcon /></NavLink>
          <NavLink active={location.pathname === "/app/stats" ? 1 : 0} to="/app/stats"><ChartIcon /></NavLink>
          <NavLink active={location.pathname === "/app/updates" ? 1 : 0} to="/app/updates"><NoteIcon /></NavLink>
        </div>
        {isGuest ? (
          <SignInIcon onClick={() => props.setShowAuthModal(true)}><AccountIcon />Sign In</SignInIcon>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: 25 }}>
            {!isGuest && <NavLink active={location.pathname === "/app/settings" ? 1 : 0} to="/app/settings"><SettingIcon /></NavLink>}
            <NavLink onClick={handleSignout} to="/app/topics"><LogoutIcon /></NavLink>
          </div>
        )}
      </Sidebar>
      <UserContext.Provider value={{ user: props.user, setUser: props.setUser, activeSub: hasActiveSub }}>
        <Content>
          <AppMobileHeader
            isGuest={isGuest}
            setShowAuthModal={props.setShowAuthModal}
            signOut={handleSignout}
            user={props.user}
          />
          {(isGuest || !hasActiveSub) && <GuestBanner />}
          <Outlet />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Footer>
              <p>Copyright © 2022 MoolaMath</p> {/* TODO: Clicking this triggers secret achievement! */}
              {/* <p>Home</p>
              <p>Practice</p>
              <p>Update</p>
              <p>Privacy</p>
              <p>Terms</p>
              <p>Contact</p> */}
              {/* TODO: Add social media icons (YouTube, Instagram) */}
            </Footer>
          </div>
        </Content>
      </UserContext.Provider>
    </Container>
  )
}

export default Dashboard