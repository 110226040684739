import firebase from 'firebase/compat/app'

// TODO: Merge set functions to single setUser function

export const addUser = (username, isBetaTester = false) => {
  const init = {
    username,
    isBetaTester,
    daily_points: {},
    exercise_points: {},
    total_points: 0,
    course: "all_topics"
  }

  return firebase.firestore().collection("users").add(init).then(() => init)
}

export const getUser = async (username) => {
  return await firebase.firestore().collection("users").where("username", "==", username).limit(1).get()
    .then(querySnap => {
      if (querySnap.docs[0]) {
        return querySnap.docs[0].data()
      }
    })
    .catch(err => ({ result: "error", message: err.message }))
}

export const addPoint = async (data) => {
  const { user, point, exKey, timeKey } = data

  return await firebase.firestore().collection("users").where("username", "==", user).limit(1).get()
    .then(querySnap => {
      const doc = querySnap.docs[0]
      const docRef = firebase.firestore().collection("users").doc(doc.id)
      const exPoints = doc.get(`exercise_points.${exKey}.points`) || 0

      docRef.update({
        total_points: firebase.firestore.FieldValue.increment(point),
        [`exercise_points.${exKey}`]: {
          points: exPoints + point,
          lastSuccess: new Date()
        },
        [`daily_points.${timeKey}.${exKey}`]: firebase.firestore.FieldValue.increment(point),
      })

      return { result: "success", message: "point(s) added" }
    })
    .catch(err => ({ result: "error", message: err.message }))
}

export const setPoint = async (data) => {
  const { user, point } = data

  return await firebase.firestore().collection("users").where("username", "==", user).limit(1).get()
    .then(querySnap => {
      const doc = querySnap.docs[0]
      return firebase.firestore().collection("users").doc(doc.id).update({
        total_points: point
      })
    })
    .then(() => ({ result: "success", message: "point(s) added" }))
    .catch(err => ({ result: "error", message: err.message }))
}

export const setCourse = async (data) => {
  const { user, course } = data

  return await firebase.firestore().collection("users").where("username", "==", user).limit(1).get()
    .then(querySnap => {
      const doc = querySnap.docs[0]
      return firebase.firestore().collection("users").doc(doc.id).update({ course })
    })
    .then(() => ({ result: "success", message: "course updated" }))
    .catch(err => ({ result: "error", message: err.message }))
}

export const setTheme = async (data) => {
  const { user, theme } = data

  return await firebase.firestore().collection("users").where("username", "==", user).limit(1).get()
    .then(querySnap => {
      const doc = querySnap.docs[0]
      return firebase.firestore().collection("users").doc(doc.id).update({ theme })
    })
    .then(() => ({ result: "success", message: "theme updated" }))
    .catch(err => ({ result: "error", message: err.message }))
}

export const setIcon = async (data) => {
  const { user, icon } = data

  return await firebase.firestore().collection("users").where("username", "==", user).limit(1).get()
    .then(querySnap => {
      const doc = querySnap.docs[0]
      return firebase.firestore().collection("users").doc(doc.id).update({ icon })
    })
    .then(() => ({ result: "success", message: "icon updated" }))
    .catch(err => ({ result: "error", message: err.message }))
}

export const setSubscription = async (data) => {
  const { user, subscription_type } = data

  return await firebase.firestore().collection("users").where("username", "==", user).limit(1).get()
    .then(querySnap => {
      const doc = querySnap.docs[0]
      return firebase.firestore().collection("users").doc(doc.id).update({ subscription_type })
    })
    .then(() => ({ result: "success", message: "subscription updated" }))
    .catch(err => ({ result: "error", message: err.message }))
}