import { TimePicker } from 'antd'
import styled from 'styled-components'
import { colors } from '../feedback'

const TimeInput = styled(TimePicker)`
  background: ${({ result = "default" }) => `${colors[result].background} !important`}; // Must be background, not backgroundColor
  border: ${({ result = "default" }) => `${result === "default" ? 1 : 2}px solid ${colors[result].border} !important`};
  font-size: 1.5em;
  margin: 10px;
`
export default TimeInput