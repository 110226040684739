import React, { useContext } from 'react'
import styled from 'styled-components'
import { UserContext } from '../../contexts'
import ProgressBar from '../../page/ExercisePage/ProgressBar'
import { getExerciseProgress, getLevel } from '../../util'

const Container = styled.div`
  border-top: 1px solid #e8e8e8;
  margin: 100px 15px 15px 15px;
  padding: 15px 0px;
`

const Title = styled.p`
  font-family: Source Sans Pro;
  font-size:  1.2em;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
`

const Row = styled.div`
  background-color: white;
  border: 1px solid #ddd;
  border-color: ${props => props.selected ? "#5FC776" : "#ddd"};
  border-radius: 3px;
  color: ${props => props.selected ? "#5FC776" : "#ddd"};
  display: flex;
  justify-content: space-between;
  font-family: Source Sans Pro;
  padding: 15px 20px;
  margin-bottom: 5px;
  width: 100%;

  &:hover {
    border-color: #5FC776;
    color: #5FC776;
    cursor: pointer;
    transition: 0.5s;
  }

  &:active {
    opacity: 0.7;
  }

  & > p {
    margin: 0
  }
`

const ExerciseList = props => {
  const { user } = useContext(UserContext)

  return (
    <Container>
      <Title>{props.title}</Title>
      <List>
        {props.items.map(item => {
          const points = getExerciseProgress(user?.exercise_points, item.key)

          return (
            <Row
              key={item.key}
              onClick={() => props.onClick(item.key)}
              selected={(item.key === props.selectedKey)}
              color="#5C9B6A"
            >
              <p>{item.label}</p>
              <ProgressBar disabled={!user?.username} filled={points % 100} label={`Level ${getLevel(points) || 1}`} width={150} />
            </Row>
          )
        })}
      </List>
    </Container>
  )
}

export default ExerciseList