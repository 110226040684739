import React, { useEffect, useMemo, useState } from 'react'
import { message, Input } from 'antd'
import styled from 'styled-components'
import { getApp } from '@firebase/app'
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth'
import { createCheckoutSession, getCurrentUserSubscriptions, getStripePayments } from '@stripe/firestore-stripe-payments'
import Button from '../component/Button'
import ToggleButton from '../component/ToggleButton'
import { Link, useNavigate } from 'react-router-dom'
import GoogleSignInButton from '../component/GoogleSignInButton'

const Container = styled.div`
  display: flex;
  height: 100vh;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const BrandPanel = styled.div`
  align-items: center;
  background-color: #5C9B6A;
  
  display: flex;
  flex-direction: column;
  font-family: Source Serif Pro;
  justify-content: center;
  padding: 15px;
  width: 30vw;

  h2 {
    color: white;
    cursor: pointer;
    font-size: 4em;
    font-weight: bold;
    margin: 0;
  }

  p {
    color: white;
    cursor: pointer;
    font-size: 1.2em;
    margin: 0;
  }

  @media only screen and (max-width: 1260px) {
    h2 {
      font-size: 3em
    }

    p {
      font-size 1em;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`

const Label = styled.div`
  font-family: Source Sans Pro;
  margin: 0px;
`

const ClickText = styled.p`
  color: #5C9B6A;
  cursor: pointer;
  font-family: Source Sans Pro;
  font-size: 12px;
  margin: 0px;
  text-align: end;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s;
  }

  &:active {
    opacity: 1;
  }
`

const MicroText = styled.p`
  font-family: Source Sans Pro;
  font-size: 11px;
  margin: 0px;
`

const prices = {
  monthly: "price_1K4ZTUARVu6QNxBwJOgkEh8o",
  annual: "price_1K4ZTUARVu6QNxBwug9oKXcx"
}

const errMsg = {
  "auth/email-already-in-use": "Email is already taken. To use this email, login to continue checkout.",
  "auth/invalid-email": "Please input a valid email",
  "auth/weak-password": "Please use a stronger password (at least 6 characters)"
}

const Registration = () => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [selectedPlan, setSelectedPlan] = useState("free")
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [termsChecked, setTermsChecked] = useState(false)
  const navigate = useNavigate()
  const auth = useMemo(() => getAuth(), [])
  const app = useMemo(() => getApp(), [])
  const payments = useMemo(() => getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers"
  }), [app])

  // Populate email field if already logged in
  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        setAuthenticated(true)
        setEmail(user.email)
      }
    })
  }, [])

  // Handle disableSubmit state
  useEffect(() => {
    if (authenticated && !!email && termsChecked) {
      setDisableSubmit(false)
    } else if (!authenticated && !!email && !!password && termsChecked) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }, [email, termsChecked])


  const openCheckout = async () => {
    setIsRedirecting(true)

    if (!authenticated) {
      await createUserWithEmailAndPassword(auth, email, password)
        .catch(err => {
          message.error(errMsg[err.code] || err.message)
          console.log("err", err)
        })
    }

    // This block catches Social Media double subscriptions
    const subs = await getCurrentUserSubscriptions(payments, { status: "active" }).catch(err => console.log("getCurrentUserSubscriptions", err))
    if (subs && subs.length > 0) {
      message.error("This email is already taken. Please choose another email.")
      setIsRedirecting(false)
      return
    }

    await createCheckoutSession(payments, {
      mode: "subscription",
      price: prices[selectedPlan],
      success_url: `${window.location.origin}/success`,
    })
      .then(session => {
        if (session) {
          window.location.assign(session.url)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setTimeout(() => setIsRedirecting(false), 2000))
  }

  const handleCreate = async () => {
    setIsRedirecting(true)

    if (!authenticated) {
      await createUserWithEmailAndPassword(auth, email, password)
        .then(() => [
          navigate("/success")
        ])
        .catch(err => {
          message.error(errMsg[err.code] || err.message)
          console.log("err", err)
        })
    }
  }

  const handleSignout = () => {
    signOut(auth)
      .then(() => {
        setAuthenticated(false)
        setEmail()
      })
      .catch(err => console.log(err))
  }

  return (
    <Container>
      <BrandPanel onClick={() => navigate(-1)}>
        <h2>MoolaMath</h2>
        <p>The Math Problem Generator</p>
      </BrandPanel>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 15 }}>
        <h2 style={{ color: "#333", fontFamily: "Source Serif Pro", fontSize: "2em", fontWeight: "bold", textAlign: "center" }}>Become a MoolaMath+ member!</h2>
        <Label>Email:</Label>
        <Input autoFocus disabled={authenticated} onChange={e => setEmail(e.target.value)} style={{ marginBottom: 10 }} value={email} />
        {!authenticated
          ? (
            <>
              <Label>Password:</Label>
              <Input.Password onChange={e => setPassword(e.target.value)} style={{ marginBottom: 10 }} value={password} />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ backgroundColor: "#ddd", height: 1, width: "100%" }} />
                <p style={{ margin: "0px 10px" }}>or</p>
                <div style={{ backgroundColor: "#ddd", height: 1, width: "100%" }} />
              </div>
              <GoogleSignInButton continue style={{ alignSelf: "center", margin: "10px 0px" }} />
            </>
          )
          : <ClickText onClick={handleSignout}>Choose another login</ClickText>
        }
        <p style={{ margin: "50px 0px 0px 0px" }}>Plan:</p>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <ToggleButton active={selectedPlan === "free"} onClick={() => setSelectedPlan("free")} style={{ margin: "0px 10px 10px 0px" }}>Beta Tester</ToggleButton>
          <ToggleButton active={selectedPlan === "monthly"} onClick={() => setSelectedPlan("monthly")} style={{ margin: "0px 10px 10px 0px" }}>Monthly $7.99</ToggleButton>
          <ToggleButton active={selectedPlan === "annual"} onClick={() => setSelectedPlan("annual")} style={{ margin: "0px 10px 10px 0px" }}>Annual $59.99</ToggleButton>
        </div>
        <MicroText>*Subscriptions can be cancelled anytime</MicroText>
        <div style={{ display: "flex", alignItems: "center", margin: "10px 0px" }}>
          <input onClick={e => setTermsChecked(e.target.checked)} style={{ height: 16, width: 16 }} type="checkbox" />
          <label style={{ fontFamily: "Source Sans Pro", margin: "0px 10px" }}>I agree to the <Link to="/terms">Terms of Service</Link></label>
        </div>
        <div>
          {selectedPlan === "free"
            ?
            <Button loading={isRedirecting} disabled={isRedirecting || disableSubmit} onClick={() => handleCreate()}>
              Create Account
            </Button>
            : (
              <Button loading={isRedirecting} disabled={isRedirecting || disableSubmit} onClick={() => openCheckout()}>
                To Checkout
              </Button>

            )
          }
        </div>
      </div>
    </Container>
  )
}

export default Registration