import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { getUser } from '../api'
import { UserContext } from '../contexts'
import ScrollToTop from '../component/ScrollToTop'
import ProblemsLocked from '../component/ProblemsLocked'

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const Topics = props => {
  const { user, setUser } = useContext(UserContext)

  useEffect(() => {
    if (!user) return
    getUser(user.username).then(res => res && res.result !== "error" && setUser(res))
  }, [])

  return (
    <Content>
      <ScrollToTop />
      {(!user && props.limitReached) ? <ProblemsLocked /> : <Outlet />}
    </Content >
  )
}

Topics.defaultProps = {
  course: "all_topics"
}

export default Topics