import React, { useState } from 'react'
import { message } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import RadioInput from '../../component/RadioInput'
import { Message } from '../../feedback'
import { generateProblem } from '../../engine'

const DivisionWordProblem = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState()
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");

  const handleRefresh = () => {
    // HACK: Small delay to prevent seeing answer of next question
    setTimeout(() => {
      setAns()
      setProblem(generateProblem(props.key))
      setAnsResult("default")
      setDisableCheck(false)
    }, 200)
  }

  const handleCheckAns = () => {
    if (ans === undefined) return
    setDisableCheck(true)

    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  const handleShowAns = () => {
    setAns(problem.ans)
    setAnsResult("default")
    setDisableCheck(true)
  }

  return (
    <ProblemBox
      title="Divison Word Problem"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      checkAnswer={handleCheckAns}
      showAnswer={handleShowAns}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p style={{ fontFamily: "Source Serif Pro", fontSize: "1.25em", marginBottom: 20 }}>{problem.question.replace("{a}", problem.a).replace("{t}", problem.a * problem.ans)}</p>
        <RadioInput
          disabled={disableCheck}
          onChange={val => setAns(val)}
          result={ansResult}
          selected={ans}
          values={problem.selections}
        />
      </div>
    </ProblemBox>
  )
}

DivisionWordProblem.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

DivisionWordProblem.defaultProps = {
  addPoint: () => { },
  name: "Division Word Problem",
  key: "divisionWordProblem"
}

export default DivisionWordProblem