import React from 'react'
import styled from 'styled-components'
import { ClipLoader } from 'react-spinners'

const Container = styled.button`
  align-items: center;
  background-color: #5FC776; 
  border-radius: 3px;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: Source Sans Pro;
  font-size: 14px;
  justify-content: center;
  outline: none;
  padding: 5px 20px;
  transition: all 0.1s;

  &:hover {
    background-color: #74E28C;
    transition: background-color 0.2s;
  }

  &:active {
    background-color: #5FC776;
  }

  &:disabled {
    background-color: #e8e8e8;
    color: #ccc;
    cursor: not-allowed;
  }
`

const Button = ({ children, loading, ...props}) => (
  <Container {...props}>
    {loading && <div style={{ marginRight: 10 }}><ClipLoader color={props.disabled ? "#ccc" : "white"} css={{ display: "flex" }} size={16} /></div>}
    {children}
  </Container>
)

export default Button