import React from 'react'
import LogoDark from '../image/logo-dark.svg'

const PageNotFound = () => (
  <div style={{ backgroundColor: "#eeffee", display: "flex", alignItems: "center", flexDirection: "column", height: "100vh", justifyContent: "center" }}>
    <h2 style={{ fontFamily: "Source Serif Pro", fontSize: "8em", margin: 0, zIndex: 1 }}>404</h2>
    <p style={{ fontFamily: "Source Sans Pro", fontSize: "1.2em", margin: 10, zIndex: 1 }}>The page you are looking for does not exist!</p>
    <img alt="logo" src={LogoDark} style={{ opacity: 0.03, height: "100vh", width: "100vw", position: "absolute" }} />
  </div>
)

export default PageNotFound