import React from 'react'

const TopicCategory = props => (
  <div style={{ borderBottom: `${props.borderless ? 0 : 1}px solid #e8e8e8`, padding: "0px 15px" }}>
    <p style={{ color: "#b0b0b0", fontFamily: "Source Sans Pro", margin: "15px 0px" }}>{props.title}</p>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {props.children}
    </div>
  </div>
)

export default TopicCategory