export const exerciseLabels = {
  addition: "Addition",
  expandedFormAddition: "Expanded Form Addition",
  subtraction: "Subtraction",
  multiplication: "Multiplication",
  multiplicationWordProblem: "Multiplication Word Problem",
  division: "Divison",
  longDivision: "Long Division",
  divisionWordProblem: "Division Word Problem",
  textToStandard: "Text To Standard Notation",
  realLifeIntegers: "Real Life Integers",
  orderingIntegers: "Order Integers",
  negativeAddition: "Negative Addition",
  negativeSubtraction: "Negative Subtraction",
  negativeMultiplication: "Negative Multiplication",
  negativeDivision: "Negative Division",
  ratioWordProblem: "Ratio Word Problem",
  convertingPercentages: "Converting Percentages",
  valuePercentage: "Value Percentage",
  fractionToDecimal: "Fraction To Decimal",
  fractionToPercentage: "Fraction To Percentage",
  comparingFractions: "Comparing Fractions",
  convertMixedNumber: "Convert Mixed Number",
  convertImproperFraction: "Convert Improper Fractions",
  addingDecimals: "Adding Decimals",
  moneyAddition: "Adding Money",
  countingMoney: "Counting Money",
  fractionAddition: "Adding Fractions",
  fractionMultiplication: "Multiplying Fractions",
  fractionDivision: "Dividing Fractions",
  exponents: "Exponents",
  expandedExponents: "ExpandedExponents",
  mean: "Mean",
  median: "Median",
  mode: "Mode",
  range: "Range",
  addingTime: "Adding Time",
  squareRoots: "Square Roots",
  evaluateRadical: "Evaluate Radical",
  addingRadicals: "Adding Radicals"
}