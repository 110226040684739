import React from 'react'
import { Collapse, Table } from 'antd'
import { exerciseLabels } from '../../engine/exerciseKeys'

const { Panel } = Collapse

const columns = [
  {
    title: 'Exercise',
    dataIndex: 'name',
    key: 'name',
    render: text => <p style={{ margin: 0 }}>{text}</p>,
    sorter: (a, b) =>  a.name.localeCompare(b.name),
    defaultSortOrder: 'ascend'
  },
  {
    title: 'Level',
    dataIndex: 'points',
    render: text => <p style={{ margin: 0 }}>{Math.ceil(text/100)}</p>,
    sorter: (a, b) => a.points - b.points
  },
  {
    title: 'Points',
    dataIndex: 'points',
    render: text => <p style={{ margin: 0 }}>{text}</p>,
    sorter: (a, b) => a.points - b.points
  },
]

const getStats = userData => {
  const stats = userData.exercise_points || {}
  return Object.entries(stats).map(ent => ({ key: ent[0], name: exerciseLabels[ent[0]] || ent[0], points: ent[1].points }))
}

const PointTable = props => (
  <Collapse defaultActiveKey={['0']} style={{ background: "white", margin: 15 }}>
    <Panel header={<p style={{ color: "rgba(0, 0, 0, 0.45)", margin: 0 }}>Exercise Points</p>}>
      <Table
        columns={columns}
        dataSource={getStats(props.user)}
        pagination={false}
        size="small"
        style={{ alignSelf: "center", backgroundColor: "white", borderRadius: "5px" }}
      />
    </Panel>
  </Collapse>
)

export default PointTable