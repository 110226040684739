import React from 'react'
import { Modal } from "antd"
import styled from 'styled-components'
import Button from '../../component/Button'
import { ReactComponent as ArrowIcon } from '../../image/arrow.svg'

const Arrow = styled(ArrowIcon)`
  cursor: pointer;
  fill: #5FC776;
  height: 16px;
  width: 16px;

  &:hover { 
    fill: #74E28C;
  }
`

const ProblemSettingsModal = props => {
  const nextLevel = () => {
    const nextKey = props.levels.findIndex(item => item.key === props.selectedDifficulty) + 1
    props.setDifficulty(props.levels[nextKey % props.levels.length].key)
  }

  const prevLevel = () => {
    const prevKey = props.levels.slice().reverse().findIndex(item => item.key === props.selectedDifficulty) + 1
    props.setDifficulty(props.levels.slice().reverse()[prevKey % props.levels.length].key)
  }

  const getLevel = key => {
    return props.levels.find(item => item.key === key)
  }

  return (
    <Modal
      closable={false}
      footer={null}
      onCancel={props.onClose}
      visible={props.showDifficultyModal}
    >
      <h2 style={{ borderBottom: "1px solid #ddd", fontFamily: "Source Serif Pro", fontSize: "1.5lem", fontWeight: "bold", margin: 0, paddingBottom: 5 }}>Problem Settings</h2>
      <div style={{ margin: "20px 0px" }}>
        <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
          <div>
            <p style={{ fontSize: 16, lineHeight: "1.5em", margin: 0 }}>Difficulty</p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Arrow onClick={prevLevel} style={{ transform: "rotate(180deg)" }} />
            <p style={{ margin: "0px 10px", textAlign: "center", width: 100 }}>{getLevel(props.selectedDifficulty).label}</p>
            <Arrow onClick={nextLevel} />
          </div>
        </div>
        <p style={{ fontSize: 12, margin: 0 }}>{getLevel(props.selectedDifficulty).desc}</p>
      </div>
      <Button onClick={props.onClose} style={{ marginTop: 25, width: "100%" }}>Close</Button>
    </Modal>
  )
}

export default ProblemSettingsModal