import React from 'react'

const operation = {
  "+": "+",
  "-": "−",
  "*": "×"
}

const VerticalEquationFormat = props => (
  <table style={{ borderBottom: "1px solid black", fontFamily: "KaTeX_Main", fontSize: "2em", lineHeight: "1em", margin: "auto" }}>
    <tbody>
      <tr>
        <td />
        <td style={{ textAlign: "right", margin: 0 }}>{props.a}</td>
      </tr>
      <tr>
        <td>{props.c ? "" : operation[props.op]}</td>
        <td style={{ textAlign: "right", margin: 0 }}>{props.b}</td>
      </tr>
      {props.c && (
        <tr>
          <td>{operation[props.op]}</td>
          <td style={{ textAlign: "right", margin: 0 }}>{props.c}</td>
        </tr>
      )}
    </tbody>
  </table>
)

export default VerticalEquationFormat