import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  align-items: center;
  background: #303030;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-top: auto;
`

const CenteredContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px 50px;
  max-width: 1260px;
  width: 100%;
`

const SiteMap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h2 {
    color: #0e589c;
    font-family: "Fredoka One";
    font-size: 2em;
    margin: 0px;
  }
`

const Column = styled.ul`
  list-style: none;
  padding: 0px;
`

const NavLink = styled.li`
  color: white;
  font-family: Source Sans Pro;
  font-size: 1.2em;
  margin: 5px 0px;

  &:hover {
    opacity: 0.8
  }
`

const Copyright = styled.div`
  align-items: center;
  color: #b0b0b0;
  font-family: Source Sans Pro;
  font-size: 1.2em;
  display: flex;
  margin: 0px;
  white-space: nowrap;
`

const SiteFooter = props => (
  <Container style={props.containerStyle}>
    <CenteredContent>
      <SiteMap>
        <h2 style={{ color: "white", fontFamily: "Source Serif Pro", fontWeight: "bold", marginBottom: 15, marginRight: 60 }}>MoolaMath</h2>
        <div style={{ display: "flex" }}>
          <Column style={{ marginRight: 30 }}>
            <Link to="/"><NavLink>Home</NavLink></Link>
            <Link to="/app/topics"><NavLink>Practice</NavLink></Link>
            <Link to="/app/updates"><NavLink>Updates</NavLink></Link>
          </Column>
          <Column style={{ margin: "0px 30px" }}>
            <Link to="/privacy"><NavLink>Privacy</NavLink></Link>
            <Link to="/terms"><NavLink>Terms</NavLink></Link>
            <Link to="/app/contact"><NavLink>Contact</NavLink></Link>
          </Column>
        </div>
      </SiteMap>
      <Copyright style={{ marginTop: 15 }}>MoolaMath © 2022</Copyright>
    </CenteredContent>
  </Container>
)

export default SiteFooter