import React from 'react'
import styled from 'styled-components'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { ReactComponent as GoogleLogo } from '../image/google-logo.svg'

const Button = styled.div`
  cursor: pointer;
  display: flex;

  p {
    color: #757575;
    font-family: Roboto;
    margin: 0px 15px;
  }

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s;
  }

  &:active {
    opacity: 1;
  }
`

const GoogleSignInButton = props => {
  const auth = getAuth()

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then(() => props.onSuccess())
      .catch(err => console.log(err));
  }

  return (
    <Button onClick={handleGoogleSignIn} {...props}>
      <GoogleLogo height={20} width={20} />
      <p>{props.continue ? "Continue with Google" : "Sign in with Google"}</p>
    </Button>
  )
}

GoogleSignInButton.defaultProps = {
  onSuccess: () => {}
}

export default GoogleSignInButton