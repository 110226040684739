import React, { useState } from 'react'
import { message } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import ProblemBox from '../_components/ProblemBox'
import TimeInput from '../../component/TimeInput'
import { generateProblem } from '../../engine'
import { Message } from '../../feedback'

const AddTime = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState(undefined)
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default")

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setAns(undefined)
    setDisableCheck(false)
    setDisableAns(false)
    setAnsResult("default")
  }

  const handleCheckAns = () => {
    if (ans === undefined) return;
    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableCheck(true)
      setDisableAns(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  const handleShowAns = () => {
    setAns(problem.ans)
    setDisableAns(true)
    setDisableCheck(true)
  }

  return (
    <ProblemBox
      title="Adding Time"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p style={{ fontFamily: "Source Serif Pro", fontSize: "1.25em", marginBottom: 20 }}>Priscilla left her house at {problem.t.format("h:mm A")} for an outdoor run. She runs for {problem.a} minutes. What time will she finish her run?</p>
        <div style={{ display: "flex" }}>
          <TimeInput
            use12Hours
            defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
            disabled={disableAns}
            format="h:mm A"
            minuteStep={5}
            onChange={time => {
              setAns(time)
              setAnsResult("default")
            }}
            result={ansResult}
            value={ans}
          />
        </div>
      </div>
    </ProblemBox>
  )
}

AddTime.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

AddTime.defaultProps = {
  addPoint: () => { },
  name: "Adding Time",
  key: "addingTime"
}

export default AddTime