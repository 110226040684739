import React, { useState } from 'react'
import { BlockMath } from 'react-katex'
import { message } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput'
import { Message } from '../../feedback'
import { generateProblem } from '../../engine'

const ConvertMixedNumber = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [numAns, setNumAns] = useState()
  const [denAns, setDenAns] = useState()
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setNumAns(undefined)
    setDenAns(undefined)
    setAnsResult("default")
    setDisableAns(false)
    setDisableCheck(false)
  }

  const handleCheckAns = () => {
    if (numAns === undefined || denAns === undefined) return;
    if (problem.evalAns(numAns, denAns)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  const handleShowAns = () => {
    setNumAns(problem.ans.num)
    setDenAns(problem.ans.den)
    setAnsResult("default")
    setDisableAns(true)
    setDisableCheck(true)
  }

  return (
    <ProblemBox
      title="Convert Mixed Numbers"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
      text="Convert mixed number to an improper fraction"
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontSize: "1.75em" }}>
          <BlockMath>{`${problem.int}\\frac{${problem.num}}{${problem.den}}=`}</BlockMath>
        </div>
        <div style={{ display: "flex", flexDirection: "column", margin: "0 10px 0 10px" }}>
          <NumberInput
            disable={disableAns}
            inputMode="numeric"
            onChange={val => {
              setNumAns(val)
              setAnsResult("default")
            }}
            result={ansResult}
            size="large"
            style={{ fontSize: "1.5em", textAlign: "center" }}
            value={numAns}
          />
          <div style={{ backgroundColor: "#777", height: "2px", margin: "5px 0 5px 0", width: "100%" }} />
          <NumberInput
            disable={disableAns}
            inputMode="numeric"
            onChange={val => {
              setDenAns(val)
              setAnsResult("default")
            }}
            result={ansResult}
            size="large"
            style={{ fontSize: "1.5em", textAlign: "center" }}
            value={denAns}
          />
        </div>
      </div>
    </ProblemBox>
  )
}

ConvertMixedNumber.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

ConvertMixedNumber.defaultProps = {
  addPoint: () => { },
  name: "Converting Mixed Numbers",
  key: "convertMixedNumber"
}

export default ConvertMixedNumber