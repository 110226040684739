import React from 'react'
import { icon as icons } from '../../component/TopicIcon'
import styled from 'styled-components'

const Container = styled.div`
  align-items: center;
  display: flex;
  width: 225px;
  &:hover {
    background-color: #f4f4f4;
    cursor: ${props => props.onClick ? "pointer" : "not-allowed"};
    transition: background-color 0.2s;
  }
  &:active {
    background-color: ${props => props.onClick ? "#e8e8e8" : "none"};
  }
  
  @media only screen and (max-width: 768px) {
    width: 185px;
    padding: 5px;
  }
`

const IconResizer = styled.div`
  display: flex;
  height: 48px;
  width: 48px;

  @media only screen and (max-width: 768px) {
    height: 40px;
    width: 40px;
  }
`
const Text = styled.div`
  color: ${props => props.disabled ? "#ccc" : "#5C9B6A"};
  font-family: Source Sans Pro;
  font-size: 1.1em;
  margin: 0px 10px;

  @media only screen and (max-width: 768px) {
    font-size: 0.9em;
  }
`

const TopicListItem = ({ icon, onClick, text }) => (
  <Container onClick={onClick}>
    <IconResizer>
      <svg viewBox="0 0 36 36">
        <image href={icons[icon || "question"]} height="25" width="25" x="5.5" y="5.5" />
      </svg>
    </IconResizer>
    <Text disabled={!onClick}>{text}</Text>
  </Container>
)

export default TopicListItem