import React, { useState } from 'react'
import { message } from 'antd'
import { BlockMath } from 'react-katex';
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput';
import { Message } from '../../feedback'
import { generateProblem } from '../../engine';

const AddingRadicals = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState()
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setAns(undefined)
    setAnsResult("default")
    setDisableAns(false)
    setDisableCheck(false)
  }

  const handleCheckAns = () => {
    if (ans === undefined) return;
    // eslint-disable-next-line 
    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  const handleShowAns = () => {
    setAns(problem.ans)
    setAnsResult("default")
    setDisableAns(true)
    setDisableCheck(true)
  }


  const renderAnsInput = () => (
    <NumberInput
      disabled={disableAns}
      inputMode="numeric"
      onChange={val => {
        setAns(val)
        setAnsResult("default")
      }}
      result={ansResult}
      size="large"
      value={ans}
    />
  )

  return (
    <ProblemBox
      title="Adding Radicals"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontSize: "1.75em" }}>
          <BlockMath>{`${problem.a}\\sqrt{${problem.r}}+${problem.b}\\sqrt{${problem.r}}=`}</BlockMath>
        </div>
        {renderAnsInput()}
      </div>
    </ProblemBox>
  )
}

AddingRadicals.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

AddingRadicals.defaultProps = {
  addPoint: () => { },
  name: "Adding Radicals",
  key: "addingRadicals",
}

export default AddingRadicals