import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput'
import { Message } from '../../feedback'
import { generateProblem } from '../../engine'

// TODO: More variance with nickles and dimes
const AddMoney = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState()
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setAns(undefined)
    setAnsResult("default")
    setDisableAns(false)
    setDisableCheck(false)
  }

  const handleCheckAns = () => {
    if (ans === undefined) return;
    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  const handleShowAns = () => {
    setAns(problem.ans)
    setAnsResult("default")
    setDisableAns(true)
    setDisableCheck(true)
  }

  return (
    <ProblemBox
      title="Adding Money"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
    >
      <p style={{ fontFamily: "Source Serif Pro", fontSize: "1.25em", marginBottom: 20 }}>If Addie has ${problem.a.toFixed(2)} and Priscilla gives her {problem.b} quarters for doing chores, how much money will Addie have?</p>
      <div style={{ display: "flex", alignItems: "center", marginBottom: 25 }}>
        <NumberInput
          disabled={disableAns}
          inputMode="numeric"
          onChange={val => {
            setAns(val)
            setAnsResult("default")
          }}
          result={ansResult}
          size="large"
          style={{ fontSize: "1.5em", margin: 10, marginLeft: 0 }}
          value={ans}
        />
        <p style={{ margin: 0 }}>dollars</p>
      </div>
    </ProblemBox>
  )
}

AddMoney.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

AddMoney.defaultProps = {
  addPoint: () => { },
  name: "Adding Money",
  key: "moneyAddition"
}

export default AddMoney