import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'antd'
import styled from 'styled-components'
import TopicListItem from './TopicListItem'
import ExerciseList from '../../exercise/_components/ExerciseList'
import { ReactComponent as _ArrowIcon } from '../../image/arrow.svg'
import course from '../../engine/course'
import { useLocation, useNavigate } from 'react-router-dom'
import ProgressBar from './ProgressBar'
import { UserContext } from '../../contexts'
import { getLevel, getTopicProgress } from '../../util'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HeaderBar = styled.div`
  display: flex;
  height: 128px;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 768px) {
    height: auto;
  }
`

const HeaderContent = styled.div`
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1260px;
  padding: 15px;
  width: 90%;

  @media only screen and (max-width: 768px) {
    padding: 10px 15px;
    width: 100%
  }
  
  h2 {
    color: #333;
    font-family: Source Serif Pro;
    font-size: 2em;
    font-weight: bold;
    line-height: 1em;
    margin: 0;
    white-space: nowrap;

    @media only screen and (max-width: 768px) {
      font-size: 1.25em;
    }
  }

  h3 {
    color: #333;
    font-family: Source Sans Pro;
    font-size: 1.2em;
    margin: 0;

    @media only screen and (max-width: 768px) {
      font-size: 1em;
    }
  }
`

const Content = styled.div`
  background-color: white;
  max-width: 1260px;
  width: 90%;

  @media only screen and (max-width: 768px) {
    width: 100%
  }
`

const ArrowIcon = styled(_ArrowIcon)`
  fill: #333;
  height: 12px;
  margin: 0px 10px;
  transform: rotate(90deg);
  width: 12px;
`

const ExercisePage = props => {
  const [exercise, setExercise] = useState(props.selectedExercise)
  const location = useLocation()
  const navigate = useNavigate()
  const { user = {} } = useContext(UserContext)
  const points = getTopicProgress(user.exercise_points, props.topicKey)

  useEffect(() => {
    setExercise(props.selectedExercise)
  }, [props.selectedExercise])

  useEffect(() => {
    if (location.state) {
      setExercise(location.state.selectedExercise)
    }
  }, [])

  return (
    <Container>
      <HeaderBar>
        <HeaderContent>
          <div>
            <Dropdown
              mouseEnterDelay={0}
              overlay={
                <div style={{ background: "white", border: "1px solid #e8e8e8", borderRadius: 5, overflow: "auto", padding: 10 }}>
                  <p style={{ borderBottom: "1px solid #e8e8e8", color: "#555", fontFamily: "Source Sans Pro", fontWeight: "bold", margin: 10, paddingBottom: 10 }}>{`${course[user.course || "all_topics"].label} Topics`}</p>
                  <div style={{ display: "flex", flexWrap: "wrap", maxHeight: 400, maxWidth: 720 }}>
                    {course[user.course || "all_topics"].topics.map(category => category.topics.map(topic => topic.endpoint
                      ? <TopicListItem key={topic.name} icon={topic.icon} onClick={() => navigate(`/app/topics/${topic.endpoint}`)} text={topic.name} />
                      : <TopicListItem key={topic.name} icon={topic.icon} text={topic.name} />
                    ))}
                  </div>
                </div>
              }
              trigger={["hover", "click"]}
            >
              <button style={{ border: "none", background: "transparent", cursor: "pointer", display: "flex", alignItems: "center", padding: 0 }}>
                <h2>{props.title}</h2>
                <ArrowIcon />
              </button>
            </Dropdown>
            <h3>{props.exercises[exercise]?.props.name}</h3>
          </div>
          <ProgressBar disabled={!user.username} filled={points % 100} label={`Level ${getLevel(points) || 1}`} />
        </HeaderContent>
      </HeaderBar>
      <Content>
        {props.exercises[exercise]}
        {props.exerciseListItems && (
          <ExerciseList
            items={props.exerciseListItems}
            onClick={setExercise}
            selectedKey={exercise}
            title={`${props.title} Exercises`}
          />
        )}
      </Content>
    </Container>
  )
}

export default ExercisePage