const LIMIT = 25

export const recordLimitPoint = () => {
  const today = new Date().toDateString()
  const limitVal = Number(localStorage.getItem("limitVal"))
  const limitDate = localStorage.getItem("limitDate")
    ? new Date(localStorage.getItem("limitDate")).toDateString()
    : new Date().toDateString()

  if (limitDate !== today) {
    localStorage.setItem("limitVal", 1)
    localStorage.setItem("limitDate", today)
    return false
  }

  if (limitVal) {
    localStorage.setItem("limitVal", limitVal + 1)
    localStorage.setItem("limitDate", today)
    if (limitVal + 1 >= LIMIT) {
      return true
    }
  } else {
    localStorage.setItem("limitVal", 1)
    localStorage.setItem("limitDate", today)
  }

  return false
}

export const isLimitReached = () => {
  const today = new Date().toDateString()
  const limitVal = Number(localStorage.getItem("limitVal"))
  const limitDate = localStorage.getItem("limitDate")
    ? new Date(localStorage.getItem("limitDate")).toDateString()
    : new Date().toDateString()

  if (today === limitDate && limitVal >= LIMIT) {
    return true
  }
  
  return false
}