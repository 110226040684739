import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { getFunctions, httpsCallable } from 'firebase/functions'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Source Sans Pro;
  height: 100%;
  justify-content: center;
  text-align: center;

  h2 {
    font-family: Source Serif Pro;
    font-size: 3em;
    margin: 15px;
  }

  h3 {
    font-size: 2em;
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 2em;
    }
    
    h3 {
      font-size: 1.5em;
    }
  }
`

const Renewal = () => {
  const [isRedirecting, setIsRedirecting] = useState(false)
  const functions = useMemo(() => getFunctions(), [])

  const openPortal = async () => {
    setIsRedirecting(true)
    const { data } = await httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink')({
      returnUrl: window.location.href,
    });
    window.location.assign(data.url);
    setTimeout(() => setIsRedirecting(false), 2000)
  }

  return (
    <Container>
      <h2>Uh oh!</h2>
      <h3>Your account is no longer active.</h3>
      <p>Renew your subscription to continue using MoolaMath!</p>
      <Button loading={isRedirecting} disabled={isRedirecting} onClick={openPortal} size="large" style={{ backgroundColor: "#5FC776", borderRadius: 3, border: "none", fontFamily: "Source Sans Pro", margin: "10px 0px" }} type="primary">
        Manage Subscription
      </Button>
    </Container>
  )
}

export default Renewal