const course = [
  {
    category: "Operations",
    topics: [
      { name: "Addition", icon: "plus", topicKey: "Addition", endpoint: "addition" },
      { name: "Subtraction", icon: "minus", topicKey: "Subtraction", endpoint: "subtraction" },
      { name: "Multiplication", icon: "cross", topicKey: "Multiplication", endpoint: "multiplication" },
      { name: "Division", icon: "divide", topicKey: "Division", endpoint: "division" },
    ]
  },
  {
    category: "Ratios & Rates",
    topics: [
      { name: "Ratios", icon: "ratio", topicKey: "Ratios", endpoint: "ratios" },
      { name: "Rates", icon: "gauge", topicKey: "Rates" },
      { name: "Percentages", icon: "percent", topicKey: "Percentages", endpoint: "percentages" },
      { name: "Unit Conversion", icon: "jug", topicKey: "Unit Conversion" },
    ]
  },
  {
    category: "Intro to Negative Numbers",
    topics: [
      { name: "Understanding Negative Numbers", icon: "bulb", topicKey: "Understanding Negatives", endpoint: "understanding-negatives" },
    ]
  },
  {
    category: "Negative Operations",
    topics: [
      { name: "Negative Addition", icon: "negPlus", topicKey: "Negative Addition", endpoint: "negative-addition" },
      { name: "Negative Subtraction", icon: "negMinus", topicKey: "Negative Subtraction", endpoint: "negative-subtraction" },
      { name: "Negative Multiplication", icon: "negCross", topicKey: "Negative Multiplication", endpoint: "negative-multiplication" },
      { name: "Negative Division", icon: "negDivide", topicKey: "Negative Division", endpoint: "negative-division" },
    ]
  },

  {
    category: "Fractions",
    topics: [
      { name: "Fractions", icon: "pieBlue", topicKey: "Proper Fractions", endpoint: "fractions" },
      { name: "Improper Fractions", icon: "pieRed", topicKey: "Improper Fractions", endpoint: "improper-fractions" },
      { name: "Mixed Numbers", icon: "pieGreen", topicKey: "Mixed Numbers", endpoint: "mixed-numbers" },
    ]
  },
  {
    category: "Operations with Fractions",
    topics: [
      { name: "Fraction Addition", icon: "piePlus", topicKey: "Fraction Addition", endpoint: "fraction-addition" },
      { name: "Fraction Subtraction", icon: "pieMinus", topicKey: "Fraction Subtraction" },
      { name: "Fraction Multiplication", icon: "pieCross", topicKey: "Fraction Multiplication", endpoint: "fraction-multiplication" },
      { name: "Fraction Division", icon: "pieDivide", topicKey: "Fraction Division", endpoint: "fraction-division" },
    ]
  },
  {
    category: "Operations with Decimals",
    topics: [
      { name: "Decimal Addition", icon: "plusDecimal", topicKey: "Decimal Addition", endpoint: "decimal-addition" },
      { name: "Money", icon: "coin", topicKey: "Money", endpoint: "money" },
    ]
  },
  {
    category: "More Operations",
    topics: [
      { name: "Exponents", icon: "exponent", topicKey: "Exponents", endpoint: "exponents" },
    ]
  },
  {
    category: "Statistics",
    topics: [
      { name: "Averages", icon: "chart", topicKey: "Averages", endpoint: "averages" },
    ]
  },
]

export default course