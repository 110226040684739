import AllTopics from './all_topics'
import FourthGrade from './fourth_grade'
import FifthGrade from './fifth_grade'
import SeventhGrade from './seventh_grade'
import PreAlgebra from './pre_algebra'

export default {
  all_topics: {
    label: "All",
    topics: AllTopics
  },
  fourth_grade: {
    label: "4th Grade",
    topics: FourthGrade
  },
  fifth_grade: {
    label: "5th Grade",
    topics: FifthGrade
  },
  seventh_grade: {
    label: "7th Grade",
    topics: SeventhGrade
  },
  pre_algebra: {
    label: "Pre-Algebra",
    topics: PreAlgebra
  }
}