import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { MenuOutlined } from '@ant-design/icons'

const Container = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`

const AppBar = styled.div`
  background: linear-gradient(#000000aa, #00000000);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`

const SignInButton = styled.button`
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 25px;
  cursor: pointer;
  height: 35px;
  margin: 10px 0px;
  outline: none;
  padding: 0px 25px;
  white-space: nowrap;

  p {
    color: #333;
    font-family: Source Sans Pro;
    font-weight: bold;
    margin: 0;
  }

  &:hover {
    background-color: #74E28C;
    transition: background-color 0.3s;
  }

  &:active {
    background-color: #5FC776;
  }
`

const Menu = styled.ul`
  align-items: center;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #00000055;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 10px 0px;
  position: absolute;
  width: 100%;
  z-index: 3;
`

const HomeMobileHeader = () => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <Container>
      <AppBar>
        <Link to="/"><h2 style={{ color: "white", fontFamily: "Source Serif Pro", fontWeight: "bold", fontSize: "2em", margin: 0 }}>MoolaMath</h2></Link>
        <button
          onClick={() => setShowMenu(!showMenu)}
          style={{ backgroundColor: "transparent", border: 0, outline: "none", height: 35, width: 35, float: "right" }}
        >
          <MenuOutlined style={{ color: "white", fontSize: "1.75em" }} />
        </button>
      </AppBar>
      {showMenu && (
        <Menu>
          <Link to="/app/topics">
            <SignInButton>
              <p>Sign In</p>
            </SignInButton>
          </Link>
          <li style={{ fontFamily: "Source Sans Pro", padding: 15 }}><Link onClick={() => setShowMenu(false)} style={{ color: "#5C9B6A" }} to="/app/topics">Practice</Link></li>
          <li style={{ fontFamily: "Source Sans Pro", padding: 15 }}><Link onClick={() => setShowMenu(false)} style={{ color: "#5C9B6A" }} to="/app/updates">Updates</Link></li>
        </Menu>
      )}
    </Container>
  )
}

export default HomeMobileHeader