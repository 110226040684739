import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const UpgradeLink = styled(Link)`
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  color: #5C9B6A;
  cursor: pointer;
  font-weight: bold;
  padding: 5px 15px;
  margin: 0px;
  
  &:hover {
    color: #5C9B6A;
    opacity: 0.8;
    transition: color 0.2s;
  }
  
  &:active {
    opacity: 1;
  }
`

const GuestBanner = () => {
  return (
    <div style={{ borderBottom: "1px solid #e8e8e8", display: "flex", fontFamily: "Source Sans Pro", alignItems: "center", justifyContent: "center"}}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", maxWidth: 1260, padding: "5px 15px", width: "100%" }}>
      <p style={{ color: "red",  margin: "10px 0px" }}>You are using MoolaMath on Free Tier!</p>
      <UpgradeLink to="/register">Upgrade</UpgradeLink>
      </div>
    </div>
  )
}

export default GuestBanner