import React, { useState } from 'react'
import { BlockMath } from 'react-katex';
import { message } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput';
import { generateProblem } from '../../engine';
import { Message } from '../../feedback';

const expand = (coe, pow) => {
  const a = []
  for (let i = 0; i < pow; i++) {
    a[i] = coe
  }

  return a.join('\\times')
}

const ExpandedExponents = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [coeAns, setCoeAns] = useState(undefined)
  const [powAns, setPowAns] = useState(undefined)
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setCoeAns(undefined)
    setPowAns(undefined)
    setAnsResult("default")
    setDisableAns(false)
    setDisableCheck(false)
  }

  const handleShowAns = () => {
    if (disableAns) return
    setCoeAns(problem.ans.coe)
    setPowAns(problem.ans.pow)
    setAnsResult("default")
    setDisableAns(true)
    setDisableCheck(true)
  }

  const handleCheckAns = () => {
    if (coeAns === undefined || powAns === undefined) return;
    if (problem.evalAns(coeAns, powAns)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  return (
    <ProblemBox
      title="Expanded Exponents"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
      text="Write the exponent"
    >
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <div style={{ fontSize: "1.75em", marginBottom: 15 }}>
          <BlockMath>{`${expand(problem.coe, problem.pow)}=\\text{?}`}</BlockMath>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ margin: 0 }}>Coefficient</p>
        <NumberInput
          disabled={disableAns}
          inputMode="numeric"
          onChange={val => {
            setCoeAns(val)
            setAnsResult("default")
          }}
          result={ansResult}
          size="large"
          style={{ fontSize: "1.5em", margin: "0 10px 0 10px" }}
          value={coeAns}
        />
        <p style={{ margin: 0 }}>Power</p>
        <NumberInput
          disabled={disableAns}
          inputMode="numeric"
          onChange={val => {
            setPowAns(val)
            setAnsResult("default")
          }}
          result={ansResult}
          size="large"
          style={{ fontSize: "1.5em", margin: "0 10px 0 10px" }}
          value={powAns}
        />
        <div style={{ fontSize: "1.75em" }}>
          {!!coeAns && !!powAns &&
            <BlockMath>{`=${coeAns}^{${powAns}}`}</BlockMath>
          }
        </div>
      </div>
    </ProblemBox>
  )
}

ExpandedExponents.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

ExpandedExponents.defaultProps = {
  addPoint: () => { },
  name: "Expanded Exponents",
  key: "expandedExponent"
}

export default ExpandedExponents