import React, { useState } from 'react'
import { InlineMath } from 'react-katex'
import { Tooltip, message } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput'
import { generateProblem } from '../../engine'
import { Message } from '../../feedback'

const Range = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState()
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default")

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setDisableAns(false)
    setDisableCheck(false)
    setAns(undefined)
    setAnsResult("default")
  }
  
  const handleCheckAns = () => {
    if (ans === undefined) return

    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }  
  
  const handleShowAns = () => {
    setDisableAns(true)
    setDisableCheck(true)
    setAns(problem.ans)
  }

  return (
    <ProblemBox
      title="Range"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
    >
      <p style={{ fontFamily: "Source Serif Pro", fontSize: "1.25em", marginBottom: 20 }}>What is the <Tooltip title="Hint: The biggest number minus the smallest number"><b style={{ color: "#5C9B6A" }}>range</b></Tooltip> for this set of numbers?</p>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "15px 0px 15px 0px" }}>
        <div style={{ fontSize: " 1.75em" }}>
          <InlineMath>{problem.nums.toString().replace(/,/g, ", ")}</InlineMath>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
          <NumberInput
            disabled={disableAns}
            inputMode="numeric"
            onChange={val => {
              setAnsResult("default")
              setAns(val)
            }}
            result={ansResult}
            size="large"
            style={{ fontSize: "1.5em" }}
            value={ans}
          />
        </div>
      </div>
    </ProblemBox>
  )
}

Range.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

Range.defaultProps = {
  addPoint: () => { },
  name: "Range",
  key: "range"
}

export default Range