import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'antd'
import styled from 'styled-components'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { ReactComponent as HomeIcon } from '../../image/home.svg'
import { ReactComponent as ChartIcon } from '../../image/chart.svg'
import { ReactComponent as NoteIcon } from '../../image/note.svg'
import { ReactComponent as SettingIcon } from '../../image/gear.svg'

const Container = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`

const AppBar = styled.div`
  align-items: center;
  background-color: #5C9B6A;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 0px 40px;
`

const SignInButton = styled.button`
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 25px;
  cursor: pointer;
  height: 35px;
  margin: 10px 0px;
  outline: none;
  padding: 0px 25px;
  white-space: nowrap;

  p {
    color: #333;
    font-family: Source Sans Pro;
    font-weight: bold;
    margin: 0;
  }

  &:hover {
    background-color: #74E28C;
    transition: background-color 0.3s;
  }

  &:active {
    background-color: #5FC776;
  }
`

const Menu = styled.div`
  align-items: center;
  background: white; 
  border-bottom: 1px solid #ddd;
  display: flex; 
  flex-direction: column;
  list-style-type: none;
  padding: 10px 0px;
  position: absolute;
  width: 100%;
  z-index: 2;
`

const NavLink = styled(Link)`
  background-color: ${props => props.active ? "#8dc38f" : "none"};
  border-radius: 5px;
  display: flex;
  padding: 8px;

  & svg {
    fill: white;
  }
`

const AppMobileHeader = props => {
  const [showMenu, setShowMenu] = useState(false)
  const location = useLocation()
  const [user, setUser] = useState()
  const auth = getAuth()

  useEffect(() => {
    onAuthStateChanged(auth, user => setUser(user))
  }, [])

  return (
    <Container>
      <AppBar>
        <NavLink active={location.pathname.includes("/app/topics") ? 1 : 0} to="/app/topics"><HomeIcon /></NavLink>
        <NavLink active={location.pathname === "/app/stats" ? 1 : 0} to="/app/stats"><ChartIcon /></NavLink>
        <NavLink active={location.pathname === "/app/updates" ? 1 : 0} to="/app/updates"><NoteIcon /></NavLink>
        <div style={{ backgroundColor: location.pathname === "/app/settings" ? "#8dc38f" : "none", display: "flex", padding: 8, borderRadius: 5 }}>
          <SettingIcon onClick={() => setShowMenu(!showMenu)} style={{ fill: "white" }} />
        </div>
      </AppBar>
      {showMenu && (
        <div style={{ position: "relative" }}>
          <Menu>
            {props.isGuest
              ? <SignInButton onClick={() => props.setShowAuthModal(true)}>Sign In</SignInButton>
              : user && <p style={{ display: "flex", alignItems: "center", margin: "10px 0px", height: 25 }}>{user.email}</p>
            }
            {user && <li style={{ fontFamily: "Source Sans Pro", padding: 10 }}><Link onClick={() => setShowMenu(false)} style={{ color: "#5C9B6A" }} to="/app/settings">Settings</Link></li>}
            {user && (
              <li style={{ padding: 5 }}>
                <Button onClick={() => props.signOut()} style={{ borderRadius: "5px", color: "#5C9B6A", padding: 0, whiteSpace: "nowrap" }} type="link">
                  Logout
                </Button>
              </li>
            )}
          </Menu>
        </div>
      )}
    </Container>
  )
}

export default AppMobileHeader