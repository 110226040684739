import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TopicProgressIcon from './TopicProgressIcon'
import PropTypes from 'prop-types'
import { getTopicProgress } from '../../util'
import { Tooltip } from 'antd'

// TODO: Rewrite this using style components + media query
const TopicButton = props => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (props.url !== undefined
    ? (
      <div style={{ alignItems: "center", display: "flex", flexDirection: "column", margin: screenWidth <= 480 ? "0px 10px 20px 0px" : "0px 20px 20px 0px", }}>
        <Link to={props.url}>
          <TopicProgressIcon color="#E4CF5D" icon={props.icon} filled={getTopicProgress(props.stats, props.topicKey) || props.progress} size={screenWidth <= 480 ? 64 : 100} />
        </Link>
        <p style={{ color: "#5C9B6A", fontFamily: "Source Sans Pro", margin: 0, textAlign: "center", width: 100 }}>{props.name}</p>
      </div>
    )
    : (
      <div style={{ alignItems: "center", display: "flex", flexDirection: "column", margin: screenWidth <= 480 ? "0px 10px 20px 0px" : "0px 20px 20px 0px" }}>
        <Tooltip title="Coming soon!" trigger={["hover", "click"]}>
          <TopicProgressIcon icon={props.icon} filled={getTopicProgress(props.stats, props.topicKey) || props.progress} size={screenWidth <= 480 ? 64 : 100} />
        </Tooltip>
        <p style={{ fontFamily: "Source Sans Pro", margin: 0, textAlign: "center", width: 100 }}>{props.name}</p>
      </div>
    )
  )
}

TopicButton.propTypes = {
  stats: PropTypes.object,
  topicKey: PropTypes.string
}

TopicButton.defaultProps = {
  stats: {},
  topicKey: ""
}

export default TopicButton