import React from 'react'
import { Card } from 'antd'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment'
import { exerciseLabels } from '../../engine/exerciseKeys'

const getDailyData = userData => {
  console.log("getDailyData::userData", userData)
  const result = []
  const d = moment()
  for (let i = 0; i < 10; i++) {
    let pt = 0;
    if (Object.keys(userData).length > 0 && Object.keys(userData.daily_points).length > 0) {
      pt = Object.values(userData.daily_points[d.format("YYYY-MM-DD")] || []).reduce((a, b) => a + b, 0)
    }

    result.push({ name: d.format("MM/DD"), val: pt, exercises: userData.daily_points[d.format("YYYY-MM-DD")] || {} })
    d.subtract(1, 'day')
  }

  return result.reverse()
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: "white", border: "1px solid #e8e8e8", padding: 10 }}>
        <p style={{ fontFamily: "Source Sans Pro", fontWeight: "bold", margin: 0 }}>{label}</p>
        <p style={{ fontFamily: "Source Sans Pro" }}>{`Points earned: ${payload[0].value}`}</p>
        {Object.entries(payload[0].payload.exercises).map(([k, v]) => (
          <p key={k} style={{ fontFamily: "Source Sans Pro", margin: 0 }}>{`${exerciseLabels[k]}: ${v}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

const ActivityChart = props => (
  <Card style={{ margin: 15 }}>
    <p style={{ color: "rgba(0, 0, 0, 0.45)", fontFamily: "Source Sans Pro" }}>Activity Chart</p>
    <ResponsiveContainer height={300} width='100%'>
      <LineChart data={getDailyData(props.user)}>
        <CartesianGrid stroke="#e8e8e8" />
        <XAxis dataKey="name" axisLine={{ stroke: "#e8e8e8" }} tickLine={false} />
        <YAxis name="points" axisLine={{ stroke: "#e8e8e8" }} width={30} tickLine={false} />
        <Tooltip content={<CustomTooltip />} />
        <Line name="points" dataKey="val" stroke="#5C9B6A" strokeWidth={2} dot={{ fill: "#5C9B6A", r: 4 }} activeDot={{ r: 6 }} />
      </LineChart>
    </ResponsiveContainer>
  </Card>
)

export default ActivityChart