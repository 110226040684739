import React from 'react'
import styled from 'styled-components'
import PriceTier from '../Home/PriceTier'

const HeaderText = styled.h2`
  color: #333;
  font-family: Source Serif Pro;
  font-size: 2em;
  font-weight: bold;
  margin: 15px 0px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 1.5em;
  }
`
const GuestWidget = () =>  (
  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: 15 }}>
    <HeaderText>Sign up to unlock stats!</HeaderText>
    <PriceTier />
  </div>
)

export default GuestWidget