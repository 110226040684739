import React, { useState } from 'react'
import { message } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput';
import { Message } from '../../feedback'
import { generateProblem } from '../../engine'

const TextToStandard = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState(undefined)
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setAns(undefined)
    setAnsResult("default")
    setDisableAns(false)
    setDisableCheck(false)
  }
  
  const handleShowAns = () => {
    setAns(problem.ans)
    setAnsResult("default")
    setDisableAns(true)
    setDisableCheck(true)
  }
  
  const handleCheckAns = () => {
    if (ans === undefined) return;
    // eslint-disable-next-line 
    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }
  
  return (
    <ProblemBox
      title="Text To Standard Notation"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
      text="Write the value of the written number"
    >
        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
          <p style={{ fontFamily: "KaTeX_Main", fontSize: "1.75em", textAlign: "center", margin: 10, marginLeft: 0 }}>{problem.text}</p>
          <NumberInput
            disabled={disableAns}
            inputMode="numeric"
            onChange={val => {
              setAns(val)
              setAnsResult("default")
            }}
            result={ansResult}
            size="large"
            style={{ fontSize: "1.5em", margin: "0 10px 0 10px", width: 150 }}
            value={ans}
          />
        </div>
    </ProblemBox>
  )
}

TextToStandard.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

TextToStandard.defaultProps = {
  addPoint: () => {},
  name: "Text To Standard Notation",
  key: "textToStandard"
}

export default TextToStandard