import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getApp } from '@firebase/app'
import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { getCurrentUserSubscriptions, getStripePayments } from '@stripe/firestore-stripe-payments'
import { addUser, getUser } from '../api'
import LogoDark from '../image/logo-dark.svg'

const Container = styled.div`
  align-items: center;
  background-color: #EEFFEE;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;

  h2 {
    font-family: Source Serif Pro;
    font-size: 3em;
    margin: 0px;
  }

  p {
    font-family: Source Sans Pro;
    font-size: 1.2em;
  }
`

const Button = styled.button`
  background-color: #5FC776;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  padding: 8px 20px;
  position: relative;
  z-index: 2;

  &:hover {
    background-color: #74E28C;
    transition: background-color 0.2s
  }

  &:active {
    background-color: #5FC776
  }
`

const RegistrationSuccess = (props) => {
  const app = useMemo(() => getApp(), [])
  const auth = useMemo(() => getAuth(), [])
  const payments = useMemo(() => getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers"
  }), [app])

  useEffect(() => {
    onAuthStateChanged(auth, async user => {
      if (!user) return

      const subs = await getCurrentUserSubscriptions(payments, { status: "active" }).catch(err => console.log("getCurrentUserSubscriptions", err))
      if (subs.length > 0) {
        getUser(user.email)
          .then(res => {
            if (!res) {
              addUser(user.email).then(res => props.setUser(res))
              console.log("Moola data initialized", user.email)
            } else {
              console.log("Moola data already exists for", user.email)
            }
          })
          .catch(err => console.log(err))
      } else {
        // You have no subscription, this is a false positive!
        // or...you're a beta user!
        getUser(user.email)
          .then(res => {
            if (!res) {
              addUser(user.email, true)
              console.log("Moola data initialized for", user.email)
            }
          })
      }
    })
  }, [])

  return (
    <Container>
      <h2>You are now a member!</h2>
      <p>Welcome to MoolaMath!</p>
      <Link to="/app/topics"><Button>To Dashboard</Button></Link>
      <img alt="logo" src={LogoDark} style={{ opacity: 0.03, height: "100vh", width: "100vw", position: "absolute" }} />
    </Container>
  )
}

export default RegistrationSuccess