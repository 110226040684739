import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { AutoComplete, Button, Dropdown, Menu } from 'antd'
import TopicCategory from './TopicCategory'
import TopicButton from './TopicButton'
import course from '../../engine/course'
import { ReactComponent as _ArrowIcon } from '../../image/arrow.svg'
import { UserContext } from '../../contexts'
import { setCourse } from '../../api'
import topicMap from './topicMap.json'
import { exerciseLabels } from '../../engine/exerciseKeys'
import { topicLabels, topicEndpoints } from '../../engine/topicKeys'

const HeaderBar = styled.div`
  display: flex;
  height: 128px;
  justify-content: center;
  width: 90%;

  @media only screen and (max-width: 768px) {
    height: auto;
    width: 100%;
  }
`

const HeaderContent = styled.div`
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1260px;
  padding: 15px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    padding: 10px 15px;
  }

  h2 {
    color: #333;
    font-family: Source Serif Pro;
    font-size: 2em;
    font-weight: bold;
    margin: 0;
    line-height: 1em;
    white-space: nowrap;

    @media only screen and (max-width: 768px) {
      font-size: 1.25em;
    }
  }
`

const Content = styled.div`
  max-width: 1260px;
  width: 90%;

  @media only screen and (max-width: 768px) {
    height: auto;
    width: 100%;
  }
`

const ArrowIcon = styled(_ArrowIcon)`
  fill: #333;
  height: 12px;
  margin: 10px;
  transform: rotate(90deg);
  width: 12px;
`

const renderItem = title => ({
  label: exerciseLabels[title] || "",
  value: title,
});

const TopicList = () => {
  const { user = {}, setUser } = useContext(UserContext)
  const navigate = useNavigate()

  const navigateTo = item => {
    let topicKey
    for (const [k, v] of Object.entries(topicMap)) {
      if (v.find(e => e === item)) {
        topicKey = k
        break;
      }
    }
    navigate(`/app/topics/${topicEndpoints[topicKey]}`, { state: { selectedExercise: item } })
  }

  const renderCourse = course => (
    course.map((category, i) => (
      <TopicCategory borderless={i === course.length - 1} key={category.category} title={category.category}>
        {category.topics.map(topic => (
          <TopicButton
            key={topic.topicKey}
            icon={topic.icon}
            name={topic.name}
            stats={user.exercise_points}
            topicKey={topic.topicKey}
            url={topic.endpoint ? `/app/topics/${topic.endpoint}` : undefined}
          />
        ))}
      </TopicCategory>
    ))
  )

  const changeCourse = (courseKey) => {
    if (user.username) {
      setCourse({ user: user.username, course: courseKey }) // API
    }
    setUser({ ...user, course: courseKey }) // Local
  }

  return (
    <>
      <HeaderBar>
        <HeaderContent>
          <Dropdown
            mouseEnterDelay={0}
            overlay={
              <Menu onClick={e => changeCourse(e.key)}>
                {Object.entries(course).map(([key, ent]) => (
                  <Menu.Item key={key}>
                    <Link replace to="/app/topics">
                      <Button style={{ color: "#5C9B6A", fontFamily: "Source Sans Pro", fontSize: "1.1em", whiteSpace: "nowrap" }} type="link">
                        {ent.label}
                      </Button>
                    </Link>
                  </Menu.Item>
                ))}
              </Menu>
            }
            trigger={["hover", "click"]}
          >
            <button style={{ border: "none", background: "transparent", cursor: "pointer", display: "flex", alignItems: "center", padding: 0 }}>
              <h2>{`${course[user.course || "all_topics"].label} Topics`}</h2>
              <ArrowIcon />
            </button>
          </Dropdown>
          <AutoComplete
            filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            options={Object.entries(topicMap).map(([k, v]) => ({ label: topicLabels[k], options: v.map(e => renderItem(e)) }))}
            onSelect={data => navigateTo(data)}
            placeholder="Search"
            style={{ color: "#333", width: 200 }}
          />
        </HeaderContent>
      </HeaderBar>
      <Content>
        {renderCourse(course[user.course || "all_topics"].topics || [])}
      </Content>
    </>
  )
}

export default TopicList