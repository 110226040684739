import React, { useState } from 'react'
import { BlockMath } from 'react-katex'
import { message } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput'
import { Message } from '../../feedback'
import { generateProblem } from '../../engine'

const DividingFractions = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [disableCheck, setDisableCheck] = useState(false)
  const [numAns, setNumAns] = useState(undefined)
  const [denAns, setDenAns] = useState(undefined)
  const [ansResult, setAnsResult] = useState("default");

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setNumAns(undefined)
    setDenAns(undefined)
    setAnsResult("default")
    setDisableCheck(false)
  }

  const handleCheckAns = () => {
    if (numAns === undefined || denAns === undefined) return;
    if (problem.evalAns(numAns, denAns)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  return (
    <ProblemBox
      title="Dividing Fractions"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      checkAnswer={handleCheckAns}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontSize: "1.75em" }}>
          <BlockMath>{`\\frac{${problem.aNum}}{${problem.aDen}}\\div\\frac{${problem.bNum}}{${problem.bDen}}=`}</BlockMath>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0px 10px" }}>
          <NumberInput
            inputMode="numeric"
            onChange={val => {
              setNumAns(val)
              setAnsResult("default")
            }}
            result={ansResult}
            size="large"
            style={{ fontSize: "1.5em", textAlign: "center" }}
            value={numAns}
          />
          <div style={{ backgroundColor: "#777", height: "2px", margin: "5px 0 5px 0", width: "100%" }} />
          <NumberInput
            inputMode="numeric"
            onChange={val => {
              setDenAns(val)
              setAnsResult("default")
            }}
            result={ansResult}
            size="large"
            style={{ fontSize: "1.5em", textAlign: "center" }}
            value={denAns}
          />
        </div>
      </div>
    </ProblemBox>
  )
}

DividingFractions.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

DividingFractions.defaultProps = {
  addPoint: () => { },
  name: "Dividing Fractions",
  key: "fractionDivision"
}

export default DividingFractions