import React from 'react'
import { Link } from 'react-router-dom'
import { Collapse } from 'antd'

const { Panel } = Collapse

const FAQ = () => (
  <div style={{ background: "white", border: "1px solid #e8e8e8", borderRadius: 5, display: "flex", alignItems: "center", margin: "50px 0px", padding: 10 }}>
    <Collapse ghost style={{ fontFamily: "Source Sans Pro", fontSize: "1.2em" }}>
      <Panel header="What is MoolaMath?" key="1" >
        <p>A math problem generator for grades 3rd - 8th. MoolaMath is available for free with a plus tier for additional benefits.</p>
      </Panel>
      <Panel header="What can I do on MoolaMath?" key="2">
        <p>Generate unlimited practice problems common grade school math topics.<br /><br />MoolaMath+ members will have the added benefit of tracking their progress, earning achievements, and several utility features such as: quiz mode, reveal answer, challenge problems, printable worksheet generation.</p>
      </Panel>
      <Panel header="How much is MoolaMath?" key="3">
        <p>MoolaMath is available for free, but with limited features. MoolaMath+ is $7.99 monthly and $59.99 annually.</p>
      </Panel>
      <Panel header="What is included with MoolaMath?" key="4">
        <p>Free users can solve up to 25 problems per day. Plus members have unlimited problems and access to charts, achievements, and many utility features.</p>
      </Panel>
      <Panel header="What is the cancellation & refund policy?" key="5">
        <p>Plus members can cancel at anytime and may ask for a refund within 7 days no questions asked by contacting support@moolamath.com.</p>
      </Panel>
      <Panel header="What updates are coming for MoolaMath?" key="6">
        <p>New problems added weekly, improved difficulty settings, quiz mode for answering bulk problem solving, challenge problems for mastery, printable worksheet generator.</p>
      </Panel>
      <Panel header="How can I help?" key="7">
        <p>If you have ideas and suggestions please submit them to the <Link to="/app/contact" style={{ color: "#5C9B6A" }}>contact form</Link></p>
      </Panel>
    </Collapse>
  </div>
)

export default FAQ