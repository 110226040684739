import { InputNumber } from 'antd'
import styled from 'styled-components'
import { colors } from '../feedback'

const NumberInput = styled(InputNumber)`
  background: ${({ result = "default" }) => colors[result].background}; // Must be background, not backgroundColor
  border: ${({ result = "default" }) => `${result === "default" ? 2 : 2}px solid ${colors[result].border}`};
  border-radius: 5px;
  font-size: 1.5em;
  margin: 10px;
  width: 100px;
`
export default NumberInput