import React, { useState } from 'react'
import { message } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import RadioInput from '../../component/RadioInput'
import { Message } from '../../feedback'
import { generateProblem } from '../../engine'

const RatioWordProblem = props => {
  const [problem, setProblem]= useState(generateProblem(props.key))
  const [ans, setAns] = useState()
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");

  const handleRefresh = () => {
    // HACK: Small delay to prevent seeing answer of next question
    setTimeout(() => {
      setAns()
      setProblem(generateProblem(props.key))
      setAnsResult("default")
      setDisableCheck(false)
    }, 200)
  }
  
  const handleCheckAns = () => {
    if (ans === undefined) return
    setDisableCheck(true)

    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  return (
    <ProblemBox
      title="Ratio Word Problem"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      checkAnswer={handleCheckAns}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>{problem.question.replace("{a}", problem.a).replace("{b}", problem.b).replace("{c}", problem.c)}</p>
        <RadioInput
          disabled={disableCheck}
          onChange={val => setAns(val)}
          result={ansResult}
          selected={ans}
          values={problem.selections}
        />
      </div>
    </ProblemBox>
  )
}

RatioWordProblem.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

RatioWordProblem.defaultProps = {
  addPoint: () => {},
  name: "Ratio Word Problem",
  key: "ratioWordProblem"
}

export default RatioWordProblem