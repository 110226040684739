import React, { useEffect, useState } from 'react'
import { Collapse, Tooltip } from 'antd'
import styled from 'styled-components'
import Course from '../../engine/course'
import { TopicIcon } from '../../component/TopicIcon'
import { getTopicProgress } from '../../util'
import topicMap from '../TopicList/topicMap.json'

const Meter = styled.div`
  background-color: #e8e8e8;
  border-radius: 10px;
  height: 8px;
  width: 150px;

  @media only screen and (max-width: 768px) {
    width: 100px;
  }
`

const Filled = styled.div`
  background-color: #E4CF5D;
  border-radius: 10px;
  height: 8px;
  width: ${props => `${props.filled / 100 * 150}px`};

  @media only screen and (max-width: 768px) {
    width: ${props => `${props.filled / 100 * 100}px`};
  }
`
const { Panel } = Collapse

const TopicProgress = props => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const renderExerciseExp = (topic) => (
    (topicMap[topic] || []).map(exercise => (
      <p key={exercise} style={{ fontFamily: "Source Sans Pro", margin: 0 }}>{`${exercise}: ${props.user.exercise_points[exercise].points}`}</p>
    ))
  )

  const renderExerciseProgress = () => {
    return (
      Course[props.user.course].topics.map(category => (
        category.topics.map(topic => {
          const points = getTopicProgress(props.user.exercise_points, topic.topicKey)

          return (
            <Tooltip color="#303030" key={topic.name} title={<><p style={{ fontFamily: "Source Sans Pro", margin: 0 }} trigger={["hover", "click"]}>Total Exp: {points}</p>{renderExerciseExp(topic.name)}</>}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: 10, width: screenWidth <= 768 ? 155 : 235 }}>
                <div><TopicIcon icon={topic.icon || "question"} size={screenWidth <= 768 ? 32 : 48} /></div>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}>
                  <p style={{ fontFamily: "Source Sans Pro", margin: 0 }}>{topic.name}</p>
                  <Meter>
                    <Filled filled={points % 100} />
                  </Meter>
                  <p style={{ fontFamily: "Source Sans Pro", fontSize: 12, margin: 0 }}>{`Level ${Math.ceil(points / 100) || 1}`}</p>
                </div>
              </div>
            </Tooltip>
          )
        })
      ))
    )
  }

  return (
    <Collapse defaultActiveKey={['0']} style={{ background: "white", margin: 15 }}>
      <Panel header={<p style={{ color: "rgba(0, 0, 0, 0.45)", fontFamily: "Source Sans Pro", margin: 0 }}>{`${Course[props.user.course].label} Topic Progress`}</p>}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {renderExerciseProgress()}
        </div>
      </Panel>
    </Collapse>
  )
}

export default TopicProgress