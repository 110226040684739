import { randomNum } from "./util"

const colors = {
  "success": {
    background: "#d4ffd4",
    border: "#67d17e"
  },
  "fail": {
    background: "#ffe4e4",
    border: "red"
  },
  "default": {
    background: "none",
    border: "#eee"
  }
}

const messages = {
  "success": [
    "Correct!",
    "You got it!",
    "Great job!",
    "Smarty-pants!",
    "You're doing great!",
    "Pat yourself on the back!",
    "Right answer :)",
    "Nailed it!"
  ],
  "fail": [
    "Incorrect, try again or reveal answer!",
    "Sorry, this is not right!",
    "This answer is wrong.",
    "Wrong answer :(",
    "Please try again!",
    "If you are having trouble, try a new problem!",
    "Give up? Reveal the answer by pressing the eye icon!",
    "Answer is incorrect!",
    "Keep trying!"
  ]
}

const Message = result => messages[result][randomNum(0, messages[result].length - 1)]

export { colors, Message }