import React, { useState } from 'react'
import { message } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput';
import { Message } from '../../feedback'
import { generateProblem } from '../../engine'

const MultiWordProblem = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState()
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setAns(undefined)
    setAnsResult("default")
    setDisableAns(false)
    setDisableCheck(false)
  }
  
  const handleShowAns = () => {
    setAns(problem.ans)
    setAnsResult("default")
    setDisableAns(true)
    setDisableCheck(true)
  }

  const handleCheckAns = () => {
    if (ans === undefined) return;
    // eslint-disable-next-line 
    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  return (
    <ProblemBox
      title="Multiplication Word Problem"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
    >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ fontFamily: "Source Serif Pro", fontSize: "1.25em", marginBottom: 20 }}>{problem.question.replace("{a}", problem.a).replace("{b}", problem.b).replace("{c}", problem.c)}</p>
          <div style={{ display: "flex", alignItems: "center", margin: "25px 0px" }}>
            <NumberInput
              disabled={disableAns}
              inputMode="numeric"
              onChange={val => {
                setAns(val)
                setAnsResult("default")
              }}
              result={ansResult}
              size="large"
              style={{ fontSize: "1.5em", margin: 10, marginLeft: 0 }}
              value={ans}
              />
            <p style={{ margin: 0 }}>{problem.unit}</p>
          </div>
        </div>
    </ProblemBox>
  )
}

MultiWordProblem.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

MultiWordProblem.defaultProps = {
  addPoint: () => {},
  name: "Multiplication Word Problem",
  key: "multiplicationWordProblem"
}

export default MultiWordProblem