import topicMap from './page/TopicList/topicMap.json'

export const randomNum = (min, max) => (
  Math.round(Math.random() * (min - max)) + max
)

export const shuffle = a => {
  let j, x, i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

export const getTopicProgress = (stats, key) => {
  let progress = 0;

  (topicMap[key] || []).forEach(exercise => {
    if (stats && stats[exercise]) {
      progress += stats[exercise].points
    }
  })

  return progress
}

export const getExerciseProgress = (stats, key) => {
  return stats?.[key]?.points || 0
}

export const getLevel = (points) => Math.ceil(points/100)
