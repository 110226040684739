import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Meter = styled.div`
  background-color: #e8e8e8;
  border-radius: 2px;
  height: 12px;
  width: ${props => props.width}px;

  @media only screen and (max-width: 768px) {
    height: 8px;
    width: 120px;
  }
`

const Filled = styled.div`
  background-color: #E4CF5D;
  border-radius: 2px;
  height: 12px;
  width: ${props => `${props.filled / 100 * props.width}px`};
  transform: scale(1);
  animation: pulse 0.5s linear;
}

  @keyframes pulse {
    0% {
      background-color: #E4CF5D;
    }

    50% {
      background-color: #f9eeb0;
    }

    100% {
      background-color: #E4CF5D;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 8px;
    width: 120px;
    width: ${props => `${props.filled / 100 * 120}px`};
  }
`

const Label = styled.p`
  color: ${props => props.disabled ? "#ddd" : "#333"};
  font-family: Source Sans Pro;
  margin: 0px 10px 0px 0px;
  white-space: nowrap;
`

const ProgressBar = (props) => (
  <Tooltip title={props.disabled ? "Upgrade to unlock!" : `${props.filled}/100`} trigger={["hover", "click"]}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <Label disabled={props.disabled}>{props.label}</Label>
      <Meter width={props.width}>
        <Filled key={props.filled} filled={props.filled} width={props.width} />
      </Meter>
    </div>
  </Tooltip>
)

ProgressBar.defaultProps = {
  disabled: false,
  filled: 0,
  label: "",
  width: 200
}

export default ProgressBar