
import { randomNum, shuffle } from '../util'
import moment from 'moment'

export const generateProblem = (key, parameters = {}) => {
  return {
    addition,
    expandedFormAddition,
    subtraction,
    multiplication,
    multiplicationWordProblem,
    division,
    longDivision,
    divisionWordProblem,
    textToStandard,
    ratioWordProblem,
    convertPercentage,
    valuePercentage,
    comparingFractions,
    realLifeIntegers,
    orderingIntegers,
    negativeAddition,
    negativeSubtraction,
    negativeMultiplication,
    negativeDivision,
    fractionAddition,
    fractionMultiplication,
    fractionDivision,
    fractionToDecimal,
    fractionToPercentage,
    convertImproperFraction,
    convertMixedNumber,
    decimalAddition,
    moneyAddition,
    countingMoney,
    evaluateExponent,
    expandedExponent,
    mean,
    median,
    mode,
    range,
    addingTime,
    squareRoots,
    evaluateRadical,
    addingRadicals
  }[key](parameters)
}

const addition = ({ difficulty = 0 }) => {
  const max = [25, 100, 500, 500, 10000, 100000]
  const a = randomNum(1, max[difficulty])
  const b = randomNum(1, max[difficulty])
  const c = randomNum(1, max[difficulty])
  const ans = difficulty > 2 ? a + b + c : a + b
  const evalAns = input => input === ans

  return { a, b, c, ans, evalAns }
}

const expandedFormAddition = () => {
  const amount = randomNum(4, 6) // Number of terms
  const terms = []
  for (let i = amount - 1; i >= 0; i--) {
    terms.push(randomNum(1, 9) * 10 ** i)
  }

  // 33% chance to remove each element, keep at least 3
  for (let i = 0; i < terms.length; i++) {
    if (terms.length > 3 && !randomNum(0, 2)) {
      terms.splice(Math.floor(Math.random() * terms.length), 1);
    }
  }

  const ans = terms.reduce((a, b) => a + b, 0)
  const evalAns = input => input === ans

  return { terms, ans, evalAns }
}

const subtraction = ({ difficulty = 0 }) => {
  const max = [25, 100, 500, 1000]
  const a = randomNum(10, max[difficulty])
  const b = randomNum(1, a)
  const ans = a - b
  const evalAns = input => input === ans

  return { a, b, ans, evalAns }
}

const multiplication = ({ difficulty = "m" }) => {
  const a = randomNum(difficulty === "m" ? 2 : 12, difficulty === "m" ? 12 : 25)
  const b = randomNum(difficulty === "h" ? 2 : 2, difficulty === "m" ? 12 : 10)
  const ans = a * b
  const evalAns = input => input === ans

  return { a, b, ans, evalAns }
}

// TODO: Two step problems and three step problems
// TODO: Refactor to handle n terms
// [min, max, ignore]
const multiplicationWordProblem = () => {
  const questions = [
    {
      q: "Cash bought {a} bags of candy for Halloween and each bag contains {b} treats. How much candy can Cash give for Halloween?",
      a: [2, 6],
      b: [15, 30],
      unit: "candies"
    },
    {
      // eslint-disable-next-line
      q: "Gabby saves ${a} per day for a pet rabbit. If she saves money for {b} days, how much money will she have saved?",
      a: [2, 10],
      b: [5, 30],
      unit: "dollars"
    },
    {
      q: "Addie, Gabby, and Maddie all earned {b} points on MoolaMath. How many points did they earn all together?",
      a: [3, 3],
      b: [20, 50],
      unit: "points"
    },
    {
      q: "Gabby and Maddie earned {b} points on MoolaMath in 3 days. How many points did they earn together?",
      a: [2, 2],
      b: [30, 150],
      unit: "points"
    },
    {
      q: "Addie spends her time watching movies {a} minutes a day. If she watches movies over {b} days, how much time did she spend watching movies?",
      a: [30, 120],
      b: [2, 5],
      unit: "minutes"
    },
    {
      q: "Benji the dog eats {a} bowls of kibble a day. If each bowl contained {b}oz. of kibble, how much kibble will Benji eat in {c} days?",
      a: [2, 4],
      b: [5, 12],
      c: [2, 5],
      unit: "ounces"
    },
    {
      q: "Benji the dog eats {a} bowls of kibble a day. If each bowl contained {c}oz. of kibble, how many bowls of kibble will Benji eat in {b} days?",
      a: [2, 4],
      b: [2, 12],
      c: [5, 12, true],
      unit: "bowls"
    },
  ]
  const q = questions[randomNum(0, questions.length - 1)]
  const a = randomNum(q.a[0], q.a[1])
  const b = randomNum(q.b[0], q.b[1])
  
  if (!!q.c) {
    const c = randomNum(q.c[0], q.c[1])
    const ans = q.c[2] ? a * b : a * b * c
    const evalAns = input => input === ans
    return { a, b, c, question: q.q, ans, unit: q.unit, evalAns }
  }
  
  const ans = a * b
  const evalAns = input => input === ans
  return { a, b, question: q.q, ans, unit: q.unit, evalAns }
}

const division = () => {
  const q = randomNum(2, 12)
  const divisor = randomNum(2, 12)
  const dividend = q * divisor
  const evalAns = input => input === q

  return { dividend, divisor, ans: q, evalAns }
}

// TODO: Add remainder problems and input
const longDivision = () => {
  const q = randomNum(12, 64)
  const divisor = randomNum(12, 64)
  const dividend = q * divisor
  const evalAns = input => input === q

  return { dividend, divisor, ans: q, evalAns }
}

const divisionWordProblem = () => {
  const questions = [
    "The weight of one serving of trail mix is {a} ounces. How many serving are there in {t} ounces of trail mix?",
    "Priscilla ran a total of {t} miles this month. If she runs {a} miles a day, how many day did she run?",
    "If Cash donates {a} dollars a day. How many days will it take for him to run out of {t} dollars?",
    "Addie watches Netflix {a} minutes a day. How many days will it take for her to watch {t} minutes of Netflix?",
    "Maddie has {t} diamonds on Royal High. If she purchased dresses for {a} diamonds each, how many can she buy?",
    "Gabby spends {a} minutes per math problem. How many problems can she finish in {t} minutes?",
    "Gabby's panda eats {a} bowls of food a day. How many days will it take to eat {t} bowls of food?"
  ]
  const question = questions[randomNum(0, questions.length - 1)]
  const a = randomNum(6, 20)
  const multiplier = randomNum(6, 25)
  const mods = [0, -1, 1, -2, 2]
  const selections = []
  mods.forEach((mod) => {
    selections.push(multiplier + mod)
  })
  shuffle(selections)
  const evalAns = input => input === multiplier

  return { a, question, selections, ans: multiplier, evalAns }
}

const textToStandard = () => {
  // TODO: Handle teen numbers (10, 19), ten/hundred thousands (10000, 999999) 
  const digit = {
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine"
  }

  //const teens = ["ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen" ,"seventeen", "eighteen", "ninteen"],
  const tenths = [
    { val: 20, text: "twenty {d}" },
    { val: 30, text: "thirty {d}" },
    { val: 40, text: "fourty {d}" },
    { val: 50, text: "fifty {d}" },
    { val: 60, text: "sixty {d}" },
    { val: 70, text: "seventy {d}" },
    { val: 80, text: "eighty {d}" },
    { val: 90, text: "ninety {d}" }
  ]

  const places = [
    { val: 10, text: "{t} " },
    { val: 100, text: "{d} hundred " },
    { val: 1000, text: "{d} thousand " },
    // { val: 1000000, text: "{d} million "}
  ]

  let text = ""
  let ans = 0

  for (let i = randomNum(0, places.length - 1); i >= 0; i--) {
    const d = randomNum(1, 9)
    text += places[i].text.replace("{d}", digit[d])

    if (text.includes("{t}")) {
      const d = randomNum(1, 9)
      const t = tenths[randomNum(0, tenths.length - 1)]
      text = text.replace("{t}", t.text)
      text = text.replace("{d}", digit[d])
      ans += t.val + d
    } else {
      ans += places[i].val * d
    }
  }

  const evalAns = input => input === ans

  return { text, ans, evalAns }
}

const ratioWordProblem = () => {
  const questions = [
    "The ratio of the number of boys to the number of girls in a choir is {a} to {b}. There are {c} girls in the choir. How many boys are in the choir?",
    "The ratio of the number of boys to the number of girls in a choir is {b} to {a}. There are {c} boys in the choir. How many girls are in the choir?",
    "The ratio of the number of girls to the number of boys in a choir is {a} to {b}. There are {c} boys in the choir. How many girls are in the choir?",
    "The ratio of the number of girls to the number of boys in a choir is {b} to {a}. There are {c} girls in the choir. How many girls are in the choir?"
  ]

  const question = questions[randomNum(0, questions.length - 1)]
  const a = randomNum(3, 20)
  const b = randomNum(2, 12)
  const c = b * randomNum(2, 12)
  const ans = c / b * a
  const selections = [c / b * a, c / b * (a + 1), c / b * (a - 1), c / b * (a + 2), c / b * (a - 2)]
  shuffle(selections)
  const evalAns = input => input === ans

  return { a, b, c, question, selections, ans, evalAns }
}

const convertPercentage = () => {
  const percent = randomNum(1, 200)
  const ans = percent / 100
  const evalAns = input => input === ans

  return { percent, ans, evalAns }
}

const valuePercentage = () => {
  const percent = randomNum(1, 10) * 5
  const a = randomNum(1, 30) * 10
  const ans = percent / 100 * a
  const evalAns = input => input === ans

  return { percent, a, ans, evalAns }
}

/* eslint-disable no-template-curly-in-string */
const realLifeIntegers = () => {
  const statements = [
    { sentence: "{v} feet below sea level", op: "-", min: 100, max: 9000 },
    { sentence: "${v} deposit into a checking account", op: "+", min: 5, max: 150 },
    { sentence: "stock market increase of {v} points", op: "+", min: 100, max: 300 },
    { sentence: "stock market decrease of {v} points", op: "-", min: 100, max: 300 },
    { sentence: "{v} feet above sea level", op: "+", min: 100, max: 9000 },
    { sentence: "a {v}-pound weight gain", op: "+", min: 1, max: 40 },
    { sentence: "a {v}-pound weight loss", op: "-", min: 1, max: 40 },
    { sentence: "{v} degrees below zero", op: "-", min: 1, max: 35 },
    { sentence: "{v} degrees above zero", op: "+", min: 1, max: 90 },
    { sentence: "withdrawal of ${v} from an ATM", op: "-", min: 20, max: 500 },
    { sentence: "receiving ${v} as a gift", op: "+", min: 20, max: 100 },
    { sentence: "spending ${v} on groceries", op: "-", min: 50, max: 100 },
    { sentence: "losing {v} hitpoints on a game", op: "-", min: 1, max: 100 },
    { sentence: "gaining {v} yards on a play", op: "+", min: 1, max: 30 },
    { sentence: "losing {v} yards on a play", op: "-", min: 1, max: 30 },
    { sentence: "the opposite of {v}", op: "-", min: 1, max: 100 },
    { sentence: "the opposite of -{v}", op: "+", min: 1, max: 100 }
  ]

  const statement = statements[randomNum(0, statements.length - 1)]
  const val = randomNum(statement.min, statement.max)
  const sentence = statement.sentence.replace("{v}", val)
  const ans = statement.op === "+" ? val : val * -1
  const evalAns = input => input === ans

  return { sentence, ans, evalAns }
}
/* eslint-disable no-template-curly-in-string */

const orderingIntegers = () => {
  const items = Array.from({ length: 6 }, (v, k) => k).map(k => {
    const max = 25
    const min = -25
    return {
      id: `item-${k}`,
      content: randomNum(min, max),
    }
  })

  const evalAns = input => {
    let min = input[0].content
    for (let i = 0; i < input.length; i++) {
      const item = input[i]
      if (min <= item.content) {
        min = item.content
      } else {
        return false
      }
    }

    return true
  }

  return { items, evalAns }
}

const negativeAddition = () => {
  const a = randomNum(-12, 12)
  const b = randomNum(-12, -1)
  const ans = a + b
  const evalAns = input => input === ans

  return { a, b, ans, evalAns }
}

// TODO: Allow 2nd term to be positive if 1st is already negative
const negativeSubtraction = () => {
  const a = randomNum(-12, 12)
  const b = randomNum(-12, -1)
  const ans = a - b
  const evalAns = input => input === ans

  return { a, b, ans, evalAns }
}

const negativeMultiplication = ({ difficulty }) => {
  const a = randomNum(-12, -1)
  const b = randomNum(-12, 12)
  const c = randomNum(-6, 6)
  const ans = difficulty === 2 ? a * b : a * b * c
  const evalAns = input => input === ans

  return { a, b, c, ans, evalAns }
}

const negativeDivision = () => {
  let q = randomNum(-12, 12)
  let divisor = randomNum(-12, 12) || randomNum(2, 12) // Remove chance of 0

  if (q > 0 && divisor > 0) {
    randomNum(0, 1) ? q *= -1 : divisor *= -1
  }
  const dividend = q * divisor
  const ans = dividend / divisor
  const evalAns = input => input === ans

  return { dividend, divisor, ans, evalAns }
}

const fractionAddition = () => {
  const aNum = randomNum(1, 12)
  let aDen = randomNum(2, 8)
  const bNum = randomNum(1, 12)
  let bDen = aDen * randomNum(1, 3)

  if (randomNum(0, 1)) { // Swap denominators (for randomness)
    bDen = [aDen, aDen = bDen][0]
  }

  const ans = Math.round((aNum / aDen + bNum / bDen) * 100) / 100
  const evalAns = (numInput, denInput) => Math.round((numInput / denInput) * 100) / 100 === ans

  return ({ aNum, aDen, bNum, bDen, ans, evalAns })
}

const fractionMultiplication = () => {
  const aNum = randomNum(1, 12)
  const aDen = randomNum(1, 12)
  const bNum = randomNum(1, 12)
  const bDen = randomNum(1, 12)

  const ans = Math.round(((aNum / aDen) * (bNum / bDen)) * 100) / 100
  const evalAns = (numInput, denInput) => Math.round((numInput / denInput) * 100) / 100 === ans

  return { aNum, aDen, bNum, bDen, ans, evalAns }
}

const fractionDivision = () => {
  const aNum = randomNum(1, 12)
  const aDen = randomNum(1, 12)
  const bNum = randomNum(1, 12)
  const bDen = randomNum(1, 12)

  const ans = Math.round(((aNum / aDen) / (bNum / bDen)) * 100) / 100
  const evalAns = (numInput, denInput) => Math.round((numInput / denInput) * 100) / 100 === ans

  return { aNum, aDen, bNum, bDen, ans, evalAns }
}

const fractionToDecimal = () => {
  // TODO: Irrational numbers like 1/3
  const validDen = [2, 4, 5, 10, 20, 100]
  const multiplier = randomNum(1, 3)
  const den = validDen[randomNum(0, validDen.length - 1)]
  const num = randomNum(1, den)
  const ans = num / den
  const evalAns = input => input === ans

  return { den: den * multiplier, num: num * multiplier, ans, evalAns }
}

const fractionToPercentage = () => {
  // TODO: Irrational numbers like 1/3
  const validDen = [2, 4, 5, 10, 20, 100]
  const multiplier = randomNum(1, 3)
  const den = validDen[randomNum(0, validDen.length - 1)]
  const num = randomNum(1, den)
  const ans = Math.round(num / den * 100)
  const evalAns = input => input === ans

  return { den: den * multiplier, num: num * multiplier, ans, evalAns }
}

const comparingFractions = () => {
  const a = { n: randomNum(1, 10), d: randomNum(1, 10) }
  const b = { n: randomNum(1, 10), d: randomNum(1, 10) }
  const c = { n: randomNum(1, 10), d: randomNum(1, 10) }
  const d = { n: randomNum(1, 10), d: randomNum(1, 10) }
  const vals = [a.n/a.d, b.n/b.d, c.n/c.d, d.n/d.d]
  const selections = [
    `\\frac{${a.n}}{${a.d}}`,
    `\\frac{${b.n}}{${b.d}}`,
    `\\frac{${c.n}}{${c.d}}`,
    `\\frac{${d.n}}{${d.d}}`
  ]
  const ans = vals.indexOf(Math.max(...vals))
  const evalAns = input => input === selections[ans]

  return { a, b, c, d, selections, ans, evalAns }
}

const convertImproperFraction = () => {
  let num = randomNum(10, 40)
  let den = randomNum(2, 8)

  // Prevent whole number answers
  if (num % den === 0) {
    num += 1
  }

  const ans = {
    int: Math.floor(num / den),
    num: num % den,
    den
  }

  const evalAns = (intInput, numInput, denInput) => Math.round((intInput * denInput + numInput) / denInput * 100) / 100 === Math.round((num / den) * 100) / 100

  return { num, den, ans, evalAns }
}

const convertMixedNumber = () => {
  const int = randomNum(2, 9)
  const num = randomNum(2, 9)
  const den = randomNum(2, 9)

  const ans = {
    num: int * den + num,
    den
  }

  const evalAns = (numInput, denInput) => Math.round((numInput / denInput) * 100) / 100 === Math.round((int * den + num) / den * 100) / 100

  return { int, num, den, ans, evalAns }
}

const decimalAddition = () => {
  const places = [10, 100, 1000, 10000]
  const a = randomNum(1, 1000) / places[randomNum(0, places.length - 1)]
  const b = randomNum(1, 1000) / places[randomNum(0, places.length - 1)]
  const ans = (a + b).toFixed(2)
  const evalAns = input => input === a + b

  return { a, b, ans, evalAns }
}

// TODO: Sentence and denomination variety
const moneyAddition = () => {
  const a = randomNum(1, 150) / 10
  const b = randomNum(1, 12)
  const ans = (a + (b * 0.25)).toFixed(2)
  const evalAns = input => input === a + (b * 0.25)

  return { a, b, ans, evalAns }
}

// TODO: Counting money visually
// TODO: Remove hard code, more diversity generation code
const countingMoney = () => {
  const denominations = { five: 500, one: 100, q: 25, d: 10, n: 5, p: 1 }
  const statements = [
    {
      sentence: "Gabby opens her piggy bank and finds {q} quarters, {d} dimes, {n} nickels, and {p} pennies. How much money does she have?",
      vars: { q: [2, 12], d: [2, 10], n: [2, 10], p: [2, 100] },
    },
    {
      sentence: "A street musician played music at the park for the afternoon. After an hour, the musician received {five} five dollar bills, {one} one dollar bills, and {q} quarters. How much change did the musician earn that day?",
      vars: { five: [2, 4], one: [2, 5], q: [2, 12] },
    },
    {
      sentence: "While searching underneath the sofa, Addie found {q} quarters, {d} dimes, {n} nickels, and {p} pennies. How much money did she find?",
      vars: { q: [2, 10], d: [2, 10], n: [2, 10], p: [2, 10] },
    },
    {
      sentence: "Maddie used a metal detector on the beach and found {q} quarters, {d} dimes, {n} nickels, and {p} pennies. How much money did she discover that day?",
      vars: { q: [2, 12], d: [2, 20], n: [2, 20], p: [2, 20] },
    },
  ]

  const statement = statements[randomNum(0, statements.length - 1)]
  let sentence = statement.sentence
  let ans = 0

  Object.entries(statement.vars).forEach(e => {
    const amt = randomNum(e[1][0], e[1][1])
    ans += denominations[e[0]] * amt
    sentence = sentence.replace(`{${e[0]}}`, amt)
  })

  ans = ans / 100 // convert to dollar unit
  const evalAns = input => input === ans

  return { sentence, ans, evalAns }
}

const evaluateExponent = () => {
  const coe = randomNum(1, 10)
  const pow = randomNum(2, 3)
  const ans = Math.pow(coe, pow)
  const evalAns = input => input === ans

  return { coe, pow, ans, evalAns }
}

const expandedExponent = () => {
  const coe = randomNum(1, 15)
  const pow = randomNum(2, 6)
  const ans = { coe, pow }
  const evalAns = (coeInput, powInput) => coeInput === coe && powInput === pow

  return { coe, pow, ans, evalAns }
}

const mean = () => {
  const min = 1
  const max = 15
  const length = randomNum(5, 7)
  const nums = []
  let sum = 0

  for (let i = 0; i < length; i++) {
    nums.push(randomNum(min, max))
    sum += nums[nums.length - 1]
  }

  // Adjusts last number to make sum divisible by length
  while (sum % length !== 0) {
    nums[nums.length - 1]++
    sum++
  }

  const ans = sum / nums.length
  const evalAns = input => input === ans
  return { nums, ans, evalAns }
}

const median = () => {
  const min = 1
  const max = 15
  const length = randomNum(5, 7)
  const nums = []
  let sum = 0

  for (let i = 0; i < length; i++) {
    nums.push(randomNum(min, max))
    sum += nums[nums.length - 1]
  }

  // Adjusts last number to make sum divisible by length
  while (sum % length !== 0) {
    nums[nums.length - 1]++
    sum++
  }

  let ans
  const numsCopy = nums.slice()
  const numsCopyLength = numsCopy.length
  numsCopy.sort((a, b) => a - b)
  if (numsCopy.length % 2 === 0) {
    ans = (numsCopy[numsCopyLength / 2] + numsCopy[(numsCopyLength / 2) - 1]) / 2
  } else {
    ans = numsCopy[Math.floor(numsCopyLength / 2)]
  }

  const evalAns = input => input === ans

  return { nums, ans, evalAns }
}

const mode = () => {
  const min = 1
  const max = 15
  const length = randomNum(5, 9)
  const getMode = nums => {
    const count = {}
    let max = 0
    const mode = new Set()

    // Count numbers
    nums.forEach(num => {
      if (count[num] === undefined) {
        count[num] = 1
      } else {
        count[num]++
      }
    })

    // Get Max Count
    nums.forEach(num => {
      if (count[num] > max) {
        max = count[num]
      }
    })

    // Store values with max count
    nums.forEach(num => {
      if (count[num] === max) {
        mode.add(num)
      }
    })

    // No mode if all numbers appear once (array matches set)
    if (mode.size === nums.length) {
      mode.clear()
    }

    return mode
  }

  const nums = []
  let sum = 0

  for (let i = 0; i < length; i++) {
    nums.push(randomNum(min, max))
    sum += nums[nums.length - 1]
  }

  // Adjusts last number to make sum divisible by length
  while (sum % length !== 0) {
    nums[nums.length - 1]++
    sum++
  }

  const ans = Array.from(getMode(nums)).join(",").toString() || "none"

  const evalAns = input => {
    let a = []
    if (input !== "none") {
      a = (input || "").replace(/\s/g, '').split(',').map(x => +x).sort((a, b) => a - b)
    }
    const b = Array.from(getMode(nums))
    b.sort((a, b) => a - b)

    return a.toString() === b.toString()
  }

  return { nums, ans, evalAns }
}

const range = () => {
  const min = 1
  const max = 25
  const length = randomNum(6, 10)
  const getRange = n => {
    const nums = n.slice()
    nums.sort((a, b) => a - b)
    return nums[nums.length - 1] - nums[0]
  }

  const nums = []
  let sum = 0

  for (let i = 0; i < length; i++) {
    nums.push(randomNum(min, max))
    sum += nums[nums.length - 1]
  }

  // Adjusts last number to make sum divisible by length
  while (sum % length !== 0) {
    nums[nums.length - 1]++
    sum++
  }

  const ans = getRange(nums)
  const evalAns = input => input === ans

  return { nums, ans, evalAns }
}

const addingTime = () => {
  const a = Math.round(randomNum(10, 75) / 5) * 5
  const t = moment({
    hour: randomNum(0, 23),
    minute: Math.round(randomNum(0, 55) / 5) * 5,
  })
  const ans = moment(t).add(a, "m")
  const evalAns = input => input.format("h:mm a") === ans.format("h:mm a")

  return { a, t, ans, evalAns }
}

const squareRoots = () => {
  const a = randomNum(1, 12)**2
  const ans = Math.sqrt(a)
  const evalAns = input => input === ans

  return { a, ans, evalAns }
}

const evaluateRadical = () => {
  const a = randomNum(2, 12)
  const b = randomNum(1, 12)**2
  const ans = a * Math.sqrt(b)
  const evalAns = input => input === ans

  return { a, b, ans, evalAns }
}

// TODO: Randomize number of terms
const addingRadicals = () => {
  const a = randomNum(2, 15)
  const b = randomNum(2, 15)
  const r = randomNum(1, 12)**2
  const ans = a * Math.sqrt(r) + b * Math.sqrt(r)
  const evalAns = input => input === ans

  return { a, b, r, ans, evalAns }
}

// TODO: Randomize roots coefficients, number of terms
const addingDiffRadicals = () => {
  const a = randomNum(2, 15)
  const b = randomNum(2, 15)
  const c = randomNum(2, 15)
  const r1 = randomNum(1, 12)**2
  const r2 = randomNum(1, 12)**2
  const ans = a * Math.sqrt(r1) + b * Math.sqrt(r2) + c * Math.sqrt(r1)
  const evalAns = input => input === ans

  return { a, b, c, r1, r2, ans, evalAns }
}

const multiplyingRadicals = () => {
  // 2sqrt(4) * 5sqrt(4)
  // = 2 * 5 * 4 
}

const multiplyingDiffRadicals = () => {
  // 2sqrt(64) * 10sqrt(16)
  // = 2 * 8 * 10 * 4
}

const cubeRoots = () => {

}

const negativeCubeRoots = () => {

}

const negativesAndRoots = () => {

}

const imaginaryNumbers = () => {

}

const factorBinomial = () => {
  const a = randomNum(1, 12)
  // Multiple choice or drag term builder
  // x^2 - 16
  //   (x+4)(x-4) - correct
  //   (x+4)(x+4) - wrong
  //   (x-4)(x-4) - wrong
  //   (x-8)(x-2) - wrong
  //   (x+8)(x-2) - wrong
  //   (x-8)(x+2) - wrong
  //   (x+8)(x+2) - wrong

  // x^2 + 81
  // x^2 - 144
}

const factorTrinomial = () => {
  // x^2 - 2x - 35 => (x-7)(x+5)
}