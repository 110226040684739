import React, { useState } from 'react'
import { message } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import RadioInput from '../../component/RadioInput'
import { generateProblem } from '../../engine'
import { Message } from '../../feedback'

const CompareFractions = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState()
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default")

  const handleRefresh = () => {
    setAns()
    setProblem(generateProblem(props.key))
    setAnsResult("default")
    setDisableCheck(false)
  }

  const handleCheckAns = () => {
    if (ans === undefined) return
    setDisableCheck(true)

    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      setAnsResult("success")
      props.addPoint(1, props.key)
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  const handleShowAns = () => {
    setAns(problem.selections[problem.ans])
    setAnsResult("default")
    setDisableCheck(true)
  }

  return (
    <ProblemBox
      title="Comparing Fractions"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      checkAnswer={handleCheckAns}
      showAnswer={handleShowAns}
      text="Choose the largest fraction"
    >
      <div style={{ margin: "20px 0px" }}>
        <RadioInput
          enableKatex
          disabled={disableCheck}
          onChange={val => setAns(val)}
          result={ansResult}
          selected={ans}
          values={problem.selections}
        />
      </div>
    </ProblemBox>
  )
}

CompareFractions.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

CompareFractions.defaultProps = {
  addPoint: () => {},
  name: "Comparing Fractions",
  key: "comparingFractions"
}

export default CompareFractions