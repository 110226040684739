import React from 'react'
import styled from 'styled-components'
import updates from './UpdateLog.json'
import ScrollToTop from '../../component/ScrollToTop'

const HeaderBar = styled.div`
  display: flex;
  height: 128px;
  justify-content: center;
  
  @media only screen and (max-width: 768px) {
    height: auto;
  }
`

const HeaderContent = styled.div`
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  height: auto;
  max-width: 1260px;
  padding: 15px;
  width: 90%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  h2 {
    color: #333;
    font-family: Source Serif Pro;
    font-size: 2em;
    font-weight: bold;
    margin: 0;
    line-height: 1em;
    white-space: nowrap;

    @media only screen and (max-width: 768px) {
      font-size: 1.25em;
    }
  }
`

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    height: auto;
    width: 100%;
  }

  table {
    max-width: 1260px;
    width: 100%;
  }
`

const Row = props => (
  <tr>
    <td style={{ borderBottom: "1px solid #e8e8e8", borderRight: "1px solid #e8e8e8", borderTop: "1px solid #e8e8e8", fontFamily: "Source Sans Pro", paddingRight: 5 }}>{props.date}</td>
    <td style={{ borderBottom: "1px solid #e8e8e8", borderTop: "1px solid #e8e8e8", fontFamily: "Source Sans Pro", padding: "5px 10px", width: "100%" }}>{props.text}</td>
  </tr>
)

const UpdateLog = () => (
  <div>
    <ScrollToTop />
    <HeaderBar>
      <HeaderContent>
        <h2>Updates</h2>
      </HeaderContent>
    </HeaderBar>
    <Content>
      <table>
        <tbody>
          {updates.map(update => (
            <Row key={update[0]} date={update[0]} text={update[1]} />
          ))}
        </tbody>
      </table>
    </Content>
  </div>
)

export default UpdateLog