import React, { useState } from 'react'
import { message } from 'antd'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput'
import PropTypes from 'prop-types'
import LongDivisionFormat from './LongDivisionFormat';
import { Message } from '../../feedback'
import { generateProblem } from '../../engine'

const LongDivision = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState(undefined)
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default");

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setAns(undefined)
    setAnsResult("default")
    setDisableAns(false)
    setDisableCheck(false)
  }

  const handleCheckAns = () => {
    if (ans === undefined) return;
    // eslint-disable-next-line 
    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  const handleShowAns = () => {
    setAns(problem.ans)
    setAnsResult("default")
    setDisableAns(true)
    setDisableCheck(true)
  }

  const renderAnsInput = () => (
    <NumberInput
      disabled={disableAns}
      inputMode="numeric"
      onChange={val => {
        setAns(val)
        setAnsResult("default")
      }}
      result={ansResult}
      size="large"
      value={ans}
    />
  )

  return (
    <ProblemBox
      title="Long Division"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <LongDivisionFormat dividend={problem.dividend} divisor={problem.divisor} />
          {renderAnsInput()}
        </div>
      </div>
    </ProblemBox>
  )
}

LongDivision.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

LongDivision.defaultProps = {
  addPoint: () => { },
  name: "Long Division",
  key: "longDivision"
}

export default LongDivision