const course = [
  {
    category: "Topics for Stefanie",
    topics: [
      { name: "Radicals", icon: "radical", topicKey: "radicals", endpoint: "radicals" },
      { name: "Polynomials", topicKey: "polynomials" },
      { name: "???", topicKey: "stef" },
    ]
  },
  {
    category: "Topics for Kat",
    topics: [
      { name: "???", topicKey: "kat" },
    ]
  },
]

export default course