import styled from 'styled-components'

const ToggleButton = styled.button`
  background-color: ${props => props.active ? "#5FC776" : "white"}; 
  border-radius: 3px;
  border: ${props => props.active ? "1px solid #5FC776" : "1px solid #555"};
  color: ${props => props.active ? "white" : "#333"};
  cursor: pointer;
  font-family: Source Sans Pro;
  outline: none;
  padding: 5px 20px;

  &:hover {
    background-color: #5FC776;
    border: 1px solid #5FC776;
    color: white;
    opacity: 0.9;
    transition: all 0.2s;
  }

  &:active {
    opacity: 0.8;
  }
`

export default ToggleButton