import React from 'react'
import { Collapse } from 'antd'
import styled from 'styled-components'

const { Panel } = Collapse

const AchievementContainer = styled.div`
  align-items: center;
  background-color: #eee;
  display: flex;
  height: 36px;
  justify-content: center;
  margin: 0px 10px 10px 0px;
  width: 36px;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;
  }
`

const Achievement = () => (
  <AchievementContainer>
    <span style={{ color: "#888", fontSize: 16 }}>?</span>
  </AchievementContainer>
)

const Achievements = () => {
  const renderAchievementGrid = () => {
    const cells = []
    for (let i = 0; i < 100; i++) {
      cells.push(<Achievement key={i} />)
    }

    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {cells}
      </div>
    )
  }

  return (
    <Collapse defaultActiveKey={['0']} style={{ background: "white", margin: 15 }}>
      <Panel header={<p style={{ color: "rgba(0, 0, 0, 0.45)", fontFamily: "Source Sans Pro", margin: 0 }}>Achievements</p>}>
        {renderAchievementGrid()}
      </Panel>
    </Collapse>
  )
}

export default Achievements