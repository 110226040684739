import React from 'react'
import { icon } from '../../component/TopicIcon'

const TopicProgressIcon = props => (
  <div className="topic-icon" style={{ background: "#fafafa", border: "1px solid #fafafa", borderRadius: 5, height: props.size, width: props.size, zIndex: 1 }}>
    <svg viewBox="0 0 36 36">
      <image href={icon[props.icon || "question"] || ""} height="25" width="25" x="5.5" y="5.5" />
      {/* <path
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#eeeeee"
        strokeWidth="2.5"
      />
      <path
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke={props.color || "#e8e8e8"}
        strokeWidth="2.5"
        strokeDasharray={`${props.filled || 0}, 100`}
      /> */}
    </svg>
  </div>
)

export default TopicProgressIcon