import React from 'react'
import Plus from '../image/plus.svg'
import Minus from '../image/minus.svg'
import Cross from '../image/multiply.svg'
import Divide from '../image/division.svg'
import Bulb from '../image/understanding-negatives.svg'
import NegativePlus from '../image/negative-plus.svg'
import NegativeMinus from '../image/negative-minus.svg'
import NegativeCross from '../image/negative-multiply.svg'
import NegativeDivide from '../image/negative-division.svg'
import Percent from '../image/percentage.svg'
import PieBlue from '../image/pie-chart.svg'
import PieRed from '../image/improper-fraction.svg'
import PieGreen from '../image/mixed-number-fraction.svg'
import Coin from '../image/coin.svg'
import PlusDecimal from '../image/decimal-addition.svg'
import PiePlus from '../image/fraction-addition.svg'
import PieMinus from '../image/fraction-subtraction.svg'
import PieCross from '../image/fraction-multiply.svg'
import PieDivide from '../image/fraction-division.svg'
import Chart from '../image/average-chart.svg'
import Exponent from '../image/exponent-custom.svg'
import Operations from '../image/order-operations-custom.svg'
import Ratio from '../image/ratio-custom.svg'
import Jug from '../image/unit-conversion.svg'
import Clock from '../image/time.svg'
import Gauge from '../image/speed.svg'
import NumberText from '../image/written-numbers.svg'
import Digits from '../image/places.svg'
import Rounding from '../image/rounding.svg'
import Radical from '../image/radicals-custom.svg'
import NegativeExponent from '../image/negative-exponent-custom.svg'
import GridChart from '../image/area.svg'
import Question from '../image/questionmark.svg'

export const icon = {
  plus: Plus,
  minus: Minus,
  cross: Cross,
  divide: Divide,
  bulb: Bulb,
  negPlus: NegativePlus,
  negMinus: NegativeMinus,
  negCross: NegativeCross,
  negDivide: NegativeDivide,
  percent: Percent,
  pieBlue: PieBlue,
  pieRed: PieRed,
  pieGreen: PieGreen,
  coin: Coin,
  plusDecimal: PlusDecimal,
  piePlus: PiePlus,
  pieMinus: PieMinus,
  pieCross: PieCross,
  pieDivide: PieDivide,
  chart: Chart,
  exponent: Exponent,
  operations: Operations,
  ratio: Ratio,
  jug: Jug,
  clock: Clock,
  gauge: Gauge,
  numberText: NumberText,
  digits: Digits,
  rounding: Rounding,
  radical: Radical,
  negExponent: NegativeExponent,
  gridChart: GridChart,
  question: Question,
}

export const TopicIcon = props => (
  <div style={{ height: props.size || 64, width: props.size || 64 }}>
    <svg viewBox="0 0 36 36">
      <image href={icon[props.icon] || ""} height="25" width="25" x="5.5" y="5.5" />
    </svg>
  </div>
)