import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import moment from 'moment'
import { message } from 'antd'
import firebase from 'firebase/compat/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import 'antd/dist/antd.css'
import 'katex/dist/katex.min.css'
import './App.css'
import { addPoint as _addPoint, getUser } from './api'
import { isLimitReached, recordLimitPoint } from './api/limit'
import firebaseConfig from './firebaseConfig'
import LoginModal from './page/LoginModal'
import Settings from './page/Settings'
import Stats from './page/Stats'
import Home from './page/Home'
import Topics from './page/Topics'
import Updates from './page/Updates'
import PageNotFound from './page/PageNotFound'
import TopicList from './page/TopicList'
import Dashboard from './page/Dashboard'
import Contact from './page/Contact'
import exerciseRoutes from './page/ExercisePage/exercisePageConfig'
import Renewal from './page/Renewal'
import Registration from './page/Registration'
import RegistrationSuccess from './page/RegistrationSuccess'
import Terms from './page/Terms'
import Privacy from './page/Privacy'
import EngineSandbox from './page/EngineSandbox'

firebase.initializeApp(firebaseConfig)
// firebase.functions().useFunctionsEmulator('http://localhost:5001')

message.config({ maxCount: 1 })

const App = () => {
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [user, setUser] = useState()
  const [limitReached, setLimitReached] = useState(isLimitReached())
  const [loading, setLoading] = useState(true)
  const auth = getAuth()

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (!user) {
        setLoading(false)
        return
      }

      getUser(user.email)
        .then(res => res && setUser(res))
        .catch(err => console.log("Auth check error:", err))
        .finally(() => setLoading(false))
    })
  }, [])

  const addPoint = (val, exKey) => {
    if (!user || !user.username) {
      const limitRes = recordLimitPoint(1) // Local storage problem limit for Free Tier
      if (limitRes) {
        setLimitReached(true)
      }
      return
    }

    const timeKey = moment().format("YYYY-MM-DD")
    const updated = { ...user }
    updated.total_points += val
    if (!updated.exercise_points[exKey]) {
      updated.exercise_points[exKey] = { // Initialize exercise point entry
        points: 1,
        lastSuccess: new Date()
      }
    } else {
      updated.exercise_points[exKey].points += val
      updated.exercise_points[exKey].lastSuccess = new Date()
    }


    if (!updated.daily_points[timeKey]) {
      updated.daily_points[timeKey] = { [exKey]: 1 } // Initialize daily point for timeKey
    } else {
      if (!updated.daily_points[timeKey][exKey]) {
        updated.daily_points[timeKey][exKey] = 1 // Initialize daily point for exercise
      } else {
        updated.daily_points[timeKey][exKey] += val
      }
    }

    setUser(updated) // Local
    _addPoint({ user: user.username, point: val, exKey, timeKey }).then(res => console.log(res)) // Firestore
  }

  return (
    <>
      <Routes>
        <Route path="/" />
        <Route index element={<Home setShowAuthModal={setShowAuthModal} />} />
        <Route path="app" element={<Dashboard loading={loading} setShowAuthModal={setShowAuthModal} setUser={setUser} user={user} />}>
          <Route path="topics" element={<Topics limitReached={limitReached} />}>
            <Route path="sandbox" element={<EngineSandbox />} />
            <Route index element={<TopicList />} />
            {exerciseRoutes(addPoint)}
          </Route>
          <Route path="contact" element={<Contact />} />
          <Route path="renewal" element={<Renewal />} />
          <Route path="settings" element={<Settings />} />
          <Route path="stats" element={<Stats />} />
          <Route path="updates" element={<Updates />} />
        </Route>
        <Route path="success" element={<RegistrationSuccess setUser={setUser} />} />
        <Route path="register" element={<Registration />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <LoginModal showAuthModal={showAuthModal} setShowAuthModal={setShowAuthModal} />
    </>
  );
}

export default App;
