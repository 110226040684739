import React, { Component } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import { generateProblem } from '../../engine'
import { colors, Message } from '../../feedback';
import { message } from 'antd'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle, result) => ({
  background: result === "default" ? "white" : colors[result].background,
  borderColor: isDragging ? "#aaa" : colors[result].border,
  borderRadius: 5,
  borderStyle: "solid",
  borderWidth: (result !== "default" || isDragging) ? "2px" : "1px",
  color: "#333",
  fontSize: "1.25em",
  margin: "0px 20px 20px 0px",
  padding: 20,
  userSelect: 'none',
  textAlign: "center",
  width: "70px",
  ...draggableStyle,
});


class OrderIntegers extends Component {
  constructor(props) {
    super(props)
    const { items, evalAns } = generateProblem(props.key)
    this.state = {
      items,
      evalAns,
      isValidAnswer: false,
      ansResult: "default"
    }
  }

  generateProblem = () => {
    const { items, evalAns } = generateProblem(this.props.key)
    this.setState({ 
      ansResult: "default", 
      isValidAnswer: false, 
      items,
      evalAns
    })
  }

  onDragEnd = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    
    this.setState({ items, ansResult: "default" });
  }

  verifyAnswer = () => {
    if (this.state.evalAns(this.state.items)) {
      message.success(Message("success"))
      this.setState({ isValidAnswer: true, ansResult: "success" })
      this.props.addPoint(1, this.props.key)
      return true
    } else {
      message.error(Message("fail"))
      this.setState({ isValidAnswer: false, ansResult: "fail" })
      return false
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <ProblemBox
        title="Ordering Integers"
        disableCheck={this.state.isValidAnswer}
        refresh={this.generateProblem}
        checkAnswer={this.verifyAnswer}
        text="Drag numbers in order of least to greatest"
      >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={this.state.isValidAnswer}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={{ display: 'flex', flexWrap: "wrap", margin: "20px 0px", overflow: 'auto' }}
                  {...provided.droppableProps}
                >
                  {this.state.items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            this.state.ansResult,
                          )}
                        >
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </ProblemBox>
    );
  }
}

OrderIntegers.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

OrderIntegers.defaultProps = {
  addPoint: () => { },
  name: "Ordering Integers",
  key: "orderingIntegers"
}


export default OrderIntegers