import React from "react"

const ControlPanel = props => (
  <svg
    height={props.height || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    {...props}
  >
    <path d="M6 18h-2v5h-2v-5h-2v-3h6v3zm-2-17h-2v12h2v-12zm11 7h-6v3h2v12h2v-12h2v-3zm-2-7h-2v5h2v-5zm11 14h-6v3h2v5h2v-5h2v-3zm-2-14h-2v12h2v-12z" />
  </svg>
);

export default ControlPanel