const course = [
  {
    category: "Operations",
    topics: [
      { name: "Addition", icon: "plus", topicKey: "Addition", endpoint: "addition" },
      { name: "Subtraction", icon: "minus", topicKey: "Subtraction", endpoint: "subtraction" },
      { name: "Multiplication", icon: "cross", topicKey: "Multiplication", endpoint: "multiplication" },
      { name: "Division", icon: "divide", topicKey: "Division", endpoint: "division" },
    ]
  },
  {
    category: "Numbers",
    topics: [
      { name: "Places", icon: "digits", topicKey: "Places" },
      { name: "Rounding", icon: "rounding", topicKey: "Rounding" },
      { name: "Written Numbers", icon: "numberText", topicKey: "Numbers", endpoint: "written-numbers" },
    ]
  },
  {
    category: "Operations with Decimals",
    topics: [
      { name: "Money", icon: "coin", topicKey: "Money", endpoint: "money" },
    ]
  },
  {
    category: "Time",
    topics: [
      { name: "Time Addition", icon: "clock", topicKey: "Time Addition", endpoint: "time-addition" },
    ]
  },
]

export default course