import React, { useState } from 'react'
import { InlineMath } from 'react-katex'
import { message, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import TextInput from '../../component/TextInput'
import { generateProblem } from '../../engine'
import { Message } from '../../feedback'

const Mode = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState()
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default")

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setDisableAns(false)
    setDisableCheck(false)
    setAns(undefined)
    setAnsResult("default")
  }

  const handleShowAns = () => { 
    setDisableAns(true)
    setDisableCheck(true)
    setAns(problem.ans)
  }

  const handleCheckAns = () => {
    if (ans === undefined) return
    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setAnsResult("success")
      setDisableAns(true)
      setDisableCheck(true)
    } else {
      setAnsResult("fail")
      message.error(Message("fail"))
    }
  }

  return (
    <ProblemBox
      title="Mode"
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
    >
      <p style={{ fontFamily: "Source Serif Pro", fontSize: "1.25em", marginBottom: 20 }}>What is the <Tooltip title="Hint: The number that appears the most. If there is a tie, then its all the tied numbers. If no numbers repeat, then there is no answer"><b style={{ color: "#5C9B6A" }}>mode</b></Tooltip> for this set of numbers? Use comma for multiple answers.</p>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "15px 0px 15px 0px" }}>
        <div style={{ fontSize: "1.75em" }}>
          <InlineMath>{problem.nums.toString().replace(/,/g, ", ")}</InlineMath>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column", marginRight: 10 }}>
          <TextInput
            disabled={disableAns}
            onChange={e => {
              setAns(e.target.value)
              setAnsResult("default")
            }}
            result={ansResult}
            size="large"
            style={{ fontSize: "1.5em" }}
            value={ans}
          />
          <p style={{ color: "#5C9B6A", margin: 0, marginTop: 10, cursor: "pointer" }} onClick={() => setAns("none")}>No answer?</p>
        </div>
      </div>
    </ProblemBox>
  )
}

Mode.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

Mode.defaultProps = {
  addPoint: () => { },
  name: "Mode",
  key: "mode"
}

export default Mode