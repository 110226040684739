import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import styled from 'styled-components'
import HomeMobileHeader from './HomeMobileHeader'
import SiteFooter from './SiteFooter'
import TrophyIcon from '../../image/trophy.svg'
import PriceTier from './PriceTier';
import FAQ from './FAQ';
import LogoDark from '../../image/logo-dark.svg'
import Students from '../../image/two-students.jpg'
import Course from '../../engine/course'
import { TopicIcon } from '../../component/TopicIcon';

const Header = styled.div`
  background: linear-gradient(#000000aa, #00000000);
  height: 90px;
  
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const HeaderCenter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1260px;
  padding: 15px;
`

const HeroContainer = styled.div`
  background-color: #eeffee;
  background: linear-gradient(#00000055, #00000055), url(${Students});
  background-repeat: no-repeat;
  background-size: cover;
`

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
  padding: 15px;
  text-align: center;
`

const Navigation = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 350px;

  a {
    color: white;
    font-family: Source Sans Pro;
    font-size: 1.2em;
    letter-spacing: 1px;
    margin: 0;
  }

  a:hover {
    color: #74E28C;
    transition: color 0.2s;
  }

  a:active {
    opacity: 0.8;
  }
`

const SignInButton = styled.button`
  background-color: transparent;
  border: 1px solid white;
  border-radius: 25px;
  cursor: pointer;
  height: 35px;
  outline: none;
  padding: 0px 25px;
  white-space: nowrap;

  p {
    color: white;
    font-family: Source Sans Pro;
    font-weight: bold;
    margin: 0;
  }

  &:hover {
    background-color: #5FC776;
    transition: background-color 0.3s;
  }

  &:active {
    background-color: #5C9B6A;
  }
`

const ActionButton = styled.button`
  background-color: #5FC776;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  margin-top: 45px;
  max-width: 400px;
  width: 100%;

  p {
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    margin: 0px;
    padding: 12px;
  }

  &:hover {
    background-color: #74E28C;
    transition: background-color 0.2s;
  }

  &:active {
    background-color: #5FC776;
  }
`

const SectionContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const HeroText = styled.h2`
  color: #333;
  font-family: Source Serif Pro;
  font-size:  3em;
  margin: 0;

  @media only screen and (max-width: 768px) {
    font-size: 2.5em;
  }
`

const SubText = styled.p`
  color: #333;
  font-family: Source Sans Pro;
  font-size: 1.5em;
  margin: 0; 
`

const HoverText = styled.p`
  cursor: pointer;
  color: #333;
  font-family: Source Sans Pro;
  margin: 15px;

  &:hover {
    color: #5FC776;
    transition: color 0.3s;
  }
`

const Home = props => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <div>
      <img alt="logo" src={LogoDark} style={{ opacity: 0.02, bottom: 0, right: 0, position: "fixed", height: "60vh", width: "60vw", zIndex: -1 }} />
      <HeroContainer>
        <Header>
          <HeaderCenter>
            <Navigation>
              <h2 style={{ color: "white", fontFamily: "Source Serif Pro", fontSize: "2em", fontWeight: "bold", margin: 0 }}>MoolaMath</h2>
              <Link to="/app/topics" style={{ fontFamily: "Source Sans Pro", fontSize: "1.2em", margin: 0 }}>Practice</Link>
              <Link to="/app/updates" style={{ fontFamily: "Source Sans Pro", fontSize: "1.2em", margin: 0 }}>Updates</Link>
            </Navigation>
            <SignInButton onClick={() => props.setShowAuthModal(true)}>
              <p>Sign In</p>
            </SignInButton>
          </HeaderCenter>
        </Header>
        <HomeMobileHeader />
        <HeroContent>
          <div style={{ margin: "50px 0px" }}>
            <HeroText style={{ color: "white", fontWeight: "bold" }}>Unlimited math problems, endless practice</HeroText>
            <SubText style={{ color: "white", textAlign: "center", width: "100%" }}>The math problem generator for all grades. Say goodbye to workbooks!</SubText>
            <Link to="/app/topics">
              <ActionButton style={{ boxShadow: "0px 4px 10px #00000099" }}>
                <p>Get Started</p>
              </ActionButton>
            </Link>
          </div>
        </HeroContent>
      </HeroContainer>
      <SectionContainer style={{ backgroundColor: "white", borderBottom: "1px solid #e8e8e8" }}>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", fontSize: "1.1em", padding: "30px 0px", maxWidth: 1260, width: "100%" }}>
          <div style={{ margin: 10 }}>
            <h2 style={{ fontFamily: "Source Serif Pro" }}>What is MoolaMath?</h2>
            <p style={{ fontFamily: "Source Sans Pro", maxWidth: 580, width: "100%" }}>MoolaMath is a math problem generator with a progression system. For every correctly answered problem you earn points to gain levels and earn achievement badges! All your progress is tracked can be reviewed on our robust charts.</p>
            {/* <p>Try a sample of our generator!</p> */}
          </div>
          <div style={{ margin: 10 }}>
            <h2 style={{ fontFamily: "Source Serif Pro" }}>Why MoolaMath?</h2>
            <p style={{ fontFamily: "Source Sans Pro", maxWidth: 580, width: "100%" }}>Our generator is frequently updated to provide you with the best problems to practice. Traditional workbooks and worksheets can run out and give you only a finite number of problems. MoolaMath provides you an unlimited source of problems to solve!</p>
          </div>
        </div>
      </SectionContainer>
      <SectionContainer>
        <HeroText style={{ fontWeight: "normal", color: "#333", margin: "65px 0px 25px 0px", textAlign: "center" }}>Choose from several topics including...</HeroText>
        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", margin: "50px 0px", maxWidth: 1800, width: "100%" }}>
          {/* TODO: Limit to 20 topics, create dummy cirriculum */}
          {
            Course["all_topics"].topics.map(category => (
              category.topics.map(topic => (
                <div style={{ display: "flex", alignItems: "center", marginBottom: screenWidth <= 768 ? 10 : 25, width: screenWidth <= 768 ? 155 : 235 }}>
                  <div><TopicIcon icon={topic.icon || "question"} size={screenWidth <= 768 ? 32 : 48} /></div>
                  <div style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}>
                    <p style={{ fontFamily: "Source Sans Pro", fontSize: "1.1em", margin: 0 }}>{topic.name}</p>
                  </div>
                </div>
              ))
            ))
          }
        </div>
      </SectionContainer>
      <SectionContainer style={{ backgroundColor: "#5C9B6A" }}>
        <div style={{ margin: "50px 15px", maxWidth: 1260, width: "100%" }}>
          <HeroText style={{ color: "white" }}>Earn points and review your progress</HeroText>
          <SubText style={{ color: "white" }}>Every correct answer improves your score to help level and earn achievements!</SubText>
          <div style={{ background: "white", borderRadius: 5, padding: 20, margin: "25px 0px" }}>
            <p style={{ color: "#333", fontFamily: "Source Sans Pro", fontSize: "1.2em" }}>Daily Activity</p>
            <ResponsiveContainer height={250} width="100%">
              <LineChart data={[
                { name: "Day 1", val: 5 },
                { name: "Day 2", val: 11 },
                { name: "Day 3", val: 8 },
                { name: "Day 4", val: 15 },
                { name: "Day 5", val: 25 },
                { name: "Day 6", val: 20 },
                { name: "Day 7", val: 20 },
                { name: "Day 8", val: 0 },
                { name: "Day 9", val: 10 },
                { name: "Day 10", val: 15 },
              ]}>
                <CartesianGrid stroke="#e8e8e8" />
                <XAxis dataKey="name" axisLine={{ stroke: "#333" }} tick={{ fill: "#333" }} tickLine={false} />
                <YAxis name="points" axisLine={{ stroke: "#333" }} width={30} tick={{ fill: "#333" }} tickLine={false} />
                <Tooltip cursor={{ fill: "#f8f8f8" }} />
                <Line name="points" dataKey="val" stroke="#5C9B6A" strokeWidth={5} activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </SectionContainer>
      <SectionContainer style={{ paddingBottom: 100 }}>
        <HeroText style={{ color: "#333", margin: "50px 0px", textAlign: "center" }}>Available free or with plus for a fee...</HeroText>
        <PriceTier />
      </SectionContainer>
      <SectionContainer style={{ backgroundColor: "#f4f4f477" }}>
        <HeroText style={{ marginTop: 50, textAlign: "center" }}>Frequently Asked Questions</HeroText>
        <div style={{ maxWidth: 800, width: "100%" }}>
          <FAQ />
        </div>
      </SectionContainer>
      <SectionContainer style={{ background: "#FFF1CD", padding: "100px 0px" }}>
        <HeroText style={{ marginBottom: 50, textAlign: "center" }}>Get started now and <span style={{ color: "#C6AA64" }}>conquer</span> your grade!</HeroText>
        <img alt="trophy" src={TrophyIcon} height={180} width={180} />
        <Link to="/register">
          <ActionButton style={{ width: 350 }}>
            <p>Create an account</p>
          </ActionButton>
        </Link>
        <Link to="/app/topics">
          <HoverText>Not ready? Try for free!</HoverText>
        </Link>
      </SectionContainer>
      <SiteFooter containerStyle={{ padding: 15 }} />
    </div >
  )
}

export default Home