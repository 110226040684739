const course = [
  {
    category: "Operations",
    topics: [
      { name: "Addition", icon: "plus", topicKey: "addition", endpoint: "addition" },
      { name: "Subtraction", icon: "minus", topicKey: "subtraction", endpoint: "subtraction" },
      { name: "Multiplication", icon: "cross", topicKey: "multiplication", endpoint: "multiplication" },
      { name: "Division", icon: "divide", topicKey: "division", endpoint: "division" },
    ]
  },
  {
    category: "Numbers",
    topics: [
      { name: "Places", icon: "digits", topicKey: "places" },
      { name: "Rounding", icon: "rounding", topicKey: "rounding" },
      { name: "Written Numbers", icon: "numberText", topicKey: "numbers", endpoint: "written-numbers" },
      { name: "Factors", topicKey: "factors" },
      { name: "Prime Numbers", topicKey: "primeNumbers" },
    ]
  },
  {
    category: "Ratios & Rates",
    topics: [
      { name: "Ratios", icon: "ratio", topicKey: "ratios", endpoint: "ratios" },
      { name: "Rates", icon: "gauge", topicKey: "rates" },
      { name: "Percentages", icon: "percent", topicKey: "percentages", endpoint: "percentages" },
      { name: "Unit Conversion", icon: "jug", topicKey: "unitConversion" },
    ]
  },
  {
    category: "Introducing Negative Numbers",
    topics: [
      { name: "Understanding Negative Numbers", icon: "bulb", topicKey: "understandingNegatives", endpoint: "understanding-negatives" },
    ]
  },
  {
    category: "Negative Operations",
    topics: [
      { name: "Negative Addition", icon: "negPlus", topicKey: "negativeAddition", endpoint: "negative-addition" },
      { name: "Negative Subtraction", icon: "negMinus", topicKey: "negativeSubtraction", endpoint: "negative-subtraction" },
      { name: "Negative Multiplication", icon: "negCross", topicKey: "negativeMultiplication", endpoint: "negative-multiplication" },
      { name: "Negative Division", icon: "negDivide", topicKey: "negativeDivision", endpoint: "negative-division" },
    ]
  },
  {
    category: "Fractions",
    topics: [
      { name: "Fractions", icon: "pieBlue", topicKey: "properFractions", endpoint: "fractions" },
      { name: "Improper Fractions", icon: "pieRed", topicKey: "improperFractions", endpoint: "improper-fractions" },
      { name: "Mixed Numbers", icon: "pieGreen", topicKey: "mixedNumbers", endpoint: "mixed-numbers" },
    ]
  },
  {
    category: "Operations with Fractions",
    topics: [
      { name: "Fraction Addition", icon: "piePlus", topicKey: "fractionAddition", endpoint: "fraction-addition" },
      { name: "Fraction Subtraction", icon: "pieMinus", topicKey: "fractionSubtraction" },
      { name: "Fraction Multiplication", icon: "pieCross", topicKey: "FractionMultiplication", endpoint: "fraction-multiplication" },
      { name: "Fraction Division", icon: "pieDivide", topicKey: "fractionDivision", endpoint: "fraction-division" },
    ]
  },
  {
    category: "Operations with Decimals",
    topics: [
      { name: "Decimal Addition", icon: "plusDecimal", topicKey: "decimalAddition", endpoint: "decimal-addition" },
      { name: "Money", icon: "coin", topicKey: "money", endpoint: "money" },
    ]
  },
  {
    category: "More Operations",
    topics: [
      { name: "Absolute Value", topicKey: "absoluteValue" },
      { name: "Exponents", icon: "exponent", topicKey: "exponents", endpoint: "exponents" },
      { name: "Order of Operations", icon: "operations", topicKey: "orderOfOperations" },
      { name: "Radicals", icon: "radical", topicKey: "radicals", endpoint: "radicals" },
      { name: "Negative Exponents", icon: "negExponent", topicKey: "negativeExponents" },
    ]
  },
  {
    category: "Charts",
    topics: [
      { name: "Area & Perimeter", icon: "gridChart", topicKey: "area" },
      { name: "Tables", icon: "gridChart", topicKey: "tables" },
      { name: "Graphs", icon: "gridChart", topicKey: "graphs" },
    ]
  },
  {
    category: "Statistics",
    topics: [
      { name: "Averages", icon: "chart", topicKey: "averages", endpoint: "averages" },
    ]
  },
  {
    category: "Geometry",
    topics: [
      { name: "Angles", topicKey: "angles" },
      { name: "Triangles", topicKey: "triangles" }
    ]
  },
  {
    category: "Time",
    topics: [
      { name: "Time Addition", icon: "clock", topicKey: "timeAddition", endpoint: "time-addition" },
    ]
  },
  {
    category: "Algebra Intro",
    topics: [
      { name: "Using Variables", topicKey: "usingVariables" },
      { name: "Using Formulas", topicKey: "usingFormulas" },
    ]
  },
]

export default course