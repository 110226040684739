import { Tooltip } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import Check from '../../image/check.svg'
import Close from '../../image/close.svg'

const ActionButton = styled.button`
  background-color: #5FC776;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  max-width: 400px;
  width: 100%;

  p {
    color: white;
    font-weight: bold;
    margin: 0px;
    padding: 12px;
  }

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s;
  }

  &:active {
    opacity: 0.7;
  }
`

const AltButton = styled.button`
  background-color: white;
  border: 1px solid #333;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  max-width: 400px;
  width: 100%;

  p {
    color: #333;
    font-weight: bold;
    margin: 0px;
    padding: 12px;
  }

  &:hover {
    background-color: #fafafa;
    transition: background-color 0.2s;
  }

  &:active {
    opacity: 0.8;
  }
`

const Table = styled.table`
  color: #333;
  margin: 15px;

  thead > tr {
    font-family: Source Serif Pro;
    font-size: 2em;
    text-align: center;
    user-select: none;
  }

  tbody > tr {
    font-family: Source Sans Pro;
    font-size: 1.2em;
    text-align: center;
    user-select: none;
  }

  th, td {
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    padding: 10px;
    height: 50px;
    width: 300px;
  }

  thead > tr > th:last-child {
    border-right: none;
  }

  tbody > tr > td:first-child {
    font-weight: bold;
  }

  tbody > tr > td:last-child {
    border-right: none;
  }

  tbody > tr:last-child > td {
    border-bottom: none;
  }

  .price-text { 
    font-size: 2em;
    font-weight: bold;
  }

  @media only screen and (max-width: 768px) {
    thead > tr {
      font-size: 1.25em;
    }

    tbody > tr {
      font-size: 1em;
    }

    .price-text {
      font-size: 1.25em;
    }
  }
`
const PriceTier = () => {
  const navigate = useNavigate()

  return (
    <Table>
      <thead>
        <tr>
          <th />
          <th>Free Tier</th>
          <th>Plus Tier</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><Tooltip color="#303030" title="The number of problems you can solve." trigger={["hover", "click"]}>Daily Problems</Tooltip></td>
          <td>25</td>
          <td>Unlimited</td>
        </tr>
        <tr>
          <td><Tooltip color="#303030" title="Your answers are recorded for metrics and achievements." trigger={["hover", "click"]}>Progress Tracking</Tooltip></td>
          <td><img alt="x mark" src={Close} height={16} width={16} /></td>
          <td><img alt="checkmark" src={Check} height={16} width={16} /></td>
        </tr>
        <tr>
          <td><Tooltip color="#303030" title="Find your strengths, weaknesses, and activity by reviewing your charts!" trigger={["hover", "click"]}>Activity Charts</Tooltip></td>
          <td><img alt="x mark" src={Close} height={16} width={16} /></td>
          <td><img alt="checkmark" src={Check} height={16} width={16} /></td>
        </tr>
        <tr>
          <td><Tooltip color="#303030" title="Can't figure out the problem? Reveal the answer and learn from your mistakes." trigger={["hover", "click"]}>Reveal Answer</Tooltip></td>
          <td><img alt="x mark" src={Close} height={16} width={16} /></td>
          <td><img alt="checkmark" src={Check} height={16} width={16} /></td>
        </tr>
        <tr>
          <td><Tooltip color="#303030" title="Earn badges and show off your accomplishments!" trigger={["hover", "click"]}>Achievements</Tooltip></td>
          <td><img alt="x mark" src={Close} height={16} width={16} /></td>
          {/* <td><img alt="checkmark" src={Check} height={16} width={16} /></td> */}
          <td>Coming soon!</td>
        </tr>
        <tr>
          <td><Tooltip color="#303030" title="Test your mastery over a topic by solving these challenging problems." trigger={["hover", "click"]}>Challenge Problems</Tooltip></td>
          <td><img alt="x mark" src={Close} height={16} width={16} /></td>
          <td>Coming soon!</td>
        </tr>
        <tr>
          <td><Tooltip color="#303030" title="Answer a series of problems and see how you performed." trigger={["hover", "click"]}>Quiz Mode</Tooltip></td>
          <td><img alt="x mark" src={Close} height={16} width={16} /></td>
          <td>Coming soon!</td>
        </tr>
        <tr>
          <td><Tooltip color="#303030" title="Generate a printable worksheet for any exercise." trigger={["hover", "click"]}>Worksheets</Tooltip></td>
          <td><img alt="x mark" src={Close} height={16} width={16} /></td>
          <td>Coming soon!</td>
        </tr>
        <tr>
          <td>Price</td>
          <td><span className="price-text">Free</span></td>
          <td><Tooltip color="#303030" title="Monthly subscription priced at $7.99/mo." trigger={["hover", "click"]}><p className="price-text" style={{ margin: 0 }}>$4.99/mo</p><p style={{ fontSize: "0.65em", margin: 0 }}>*with annual subscription</p></Tooltip></td>
        </tr>
        <tr>
          <td />
          <td><AltButton onClick={() => navigate("/app/topics")}><p>Try as Guest</p></AltButton></td>
          <td><ActionButton onClick={() => navigate("/register")}><p>Create an account</p></ActionButton></td>
        </tr>

      </tbody>
    </Table>
  )
}
export default PriceTier