import React, { useState } from 'react'
import { BlockMath } from 'react-katex';
import { message } from 'antd'
import PropTypes from 'prop-types'
import ProblemBox from '../_components/ProblemBox'
import NumberInput from '../../component/NumberInput'
import { generateProblem } from '../../engine';
import { Message } from '../../feedback';

const FractionToPercentage = props => {
  const [problem, setProblem] = useState(generateProblem(props.key))
  const [ans, setAns] = useState()
  const [disableAns, setDisableAns] = useState(false)
  const [disableCheck, setDisableCheck] = useState(false)
  const [ansResult, setAnsResult] = useState("default")

  const handleRefresh = () => {
    setProblem(generateProblem(props.key))
    setAns(undefined)
    setAnsResult("default")
    setDisableAns(false)
    setDisableCheck(false)
  }
  
  const handleShowAns = () => {
    setAns(problem.ans)
    setAnsResult("default")
    setDisableAns(true)
    setDisableCheck(true)
  }
  
  const handleCheckAns = () => {
    if (ans === undefined) return;
    // eslint-disable-next-line 
    if (problem.evalAns(ans)) {
      message.success(Message("success"))
      props.addPoint(1, props.key)
      setDisableAns(true)
      setDisableCheck(true)
      setAnsResult("success")
    } else {
      message.error(Message("fail"))
      setAnsResult("fail")
    }
  }

  return (
    <ProblemBox
      title={`Fraction to Percentage`}
      disableCheck={disableCheck}
      refresh={handleRefresh}
      showAnswer={handleShowAns}
      checkAnswer={handleCheckAns}
      text="Convert the fraction to a percentage"
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontSize: "1.75em"}}>
          <BlockMath>{`\\frac{${problem.num}}{${problem.den}}=`}</BlockMath>
        </div>
        <NumberInput
          disabled={disableAns}
          inputMode="numeric"
          onChange={val => {
            setAns(val)
            setAnsResult("default")
          }}
          result={ansResult}
          size="large"
          style={{ fontSize: "1.5em", margin: "0 10px 0 10px" }}
          value={ans}
        />
        <BlockMath>{`\\%`}</BlockMath>
      </div>
    </ProblemBox>
  )
}

FractionToPercentage.propTypes = {
  addPoint: PropTypes.func,
  key: PropTypes.string,
}

FractionToPercentage.defaultProps = {
  addPoint: () => {},
  name: "Fraction to Percentage",
  key: "fractionToPercentage"
}

export default FractionToPercentage