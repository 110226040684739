import React, { useEffect, useMemo, useState } from 'react'
import { Button, message } from 'antd'
import styled from 'styled-components'
import { getApp } from '@firebase/app'
import { onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth } from '@firebase/auth'
import { getCurrentUserSubscriptions, getProducts, getStripePayments } from '@stripe/firestore-stripe-payments'
import moment from 'moment'

const HeaderBar = styled.div`
  display: flex;
  height: 128px;
  justify-content: center;
  
  @media only screen and (max-width: 768px) {
    height: auto;
  }
`

const HeaderContent = styled.div`
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  height: auto;
  max-width: 1260px;
  padding: 15px;
  width: 90%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  h2 {
    color: #333;
    font-family: Source Serif Pro;
    font-size: 2em;
    font-weight: bold;
    margin: 0;
    line-height: 1em;
    white-space: nowrap;

    @media only screen and (max-width: 768px) {
      font-size: 1.25em;
    }
  }
`

const Content = styled.div`
  margin: auto;
  max-width: 1260px;
  width: 90%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const Settings = props => {
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [subs, setSubs] = useState([])
  const [products, setProducts] = useState([])
  const [user, setUser] = useState()
  const auth = useMemo(() => getAuth(), [])
  const functions = useMemo(() => getFunctions(), [])
  const app = useMemo(() => getApp(), [])
  const payments = useMemo(() => getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers"
  }), [app])

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      console.log("user", user)
      setUser(user)
    }, [])

    getCurrentUserSubscriptions(payments, { status: "active" })
      .then(res => {
        console.log("subs", res)
        setSubs(res)
      })
      .catch(err => console.log("getCurrentUserSubscriptions err", err))

    async function getProductDetails() {
      await getProducts(payments, {
        includePrices: true,
        activeOnly: true,
      }).then(res => {
        console.log("getProducts res", res)
        setProducts(res)
      })
    }
    getProductDetails()
  }, [])

  const getPlanLabel = (subscription) => {
    const p = products.find(item => item.id === subscription?.product)
    return p?.name
  }

  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, user.email)
      .then(() => message.info("Password reset email sent!"))
      .catch(err => message.error(err.message))
  }

  const openPortal = async () => {
    setIsRedirecting(true)
    const { data } = await httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink')({
      returnUrl: window.location.href,
    });
    window.location.assign(data.url);
    setTimeout(() => setIsRedirecting(false), 2000)
  }

  return (
    <div>
      <HeaderBar>
        <HeaderContent>
          <h2>Account Settings</h2>
        </HeaderContent>
      </HeaderBar>
      <Content>
        <div style={{ margin: 15 }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "20px 0px" }}>
            <p style={{ fontFamily: "Source Sans Pro", fontSize: 16, margin: 0 }}>Email</p>
            <p style={{ fontFamily: "Source Sans Pro", fontSize: 16, margin: 0 }}>{`${user && user.email}`}</p>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "20px 0px" }}>
            <p style={{ fontFamily: "Source Sans Pro", fontSize: 16, margin: 0 }}>Manage Password</p>
            <Button onClick={handleResetPassword} size="large" style={{ backgroundColor: "#5FC776", borderRadius: 3, border: "none", fontFamily: "Source Sans Pro" }} type="primary">Reset Password</Button>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "20px 0px" }}>
            <p style={{ fontFamily: "Source Sans Pro", fontSize: 16, margin: 0 }}>Subscription</p>
            <Button loading={isRedirecting} disabled={isRedirecting} onClick={openPortal} size="large" style={{ backgroundColor: "#5FC776", borderRadius: 3, border: "none", color: "white", fontFamily: "Source Sans Pro" }} type="primary">
              Manage Subscription
            </Button>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "20px 0px 20px 20px" }}>
            <p style={{ fontFamily: "Source Sans Pro", fontSize: 16, margin: 0 }}>Plan</p>
            <p style={{ fontFamily: "Source Sans Pro", fontSize: 16, margin: 0 }}>{getPlanLabel(subs[0])}</p>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "20px 0px 20px 20px" }}>
            <p style={{ fontFamily: "Source Sans Pro", fontSize: 16, margin: 0 }}>Subscription End</p>
            <p style={{ fontFamily: "Source Sans Pro", fontSize: 16, margin: 0 }}>{subs[0]?.current_period_end && moment(subs[0]?.current_period_end).format("MMM D, YYYY")}</p>
          </div>
        </div>
      </Content>
    </div >
  )
}

export default Settings