const course = [
  {
    category: "Operations",
    topics: [
      { name: "Addition", icon: "plus", topicKey: "Addition", endpoint: "addition" },
      { name: "Subtraction", icon: "minus", topicKey: "Subtraction", endpoint: "subtraction" },
      { name: "Multiplication", icon: "cross", topicKey: "Multiplication", endpoint: "multiplication" },
      { name: "Division", icon: "divide", topicKey: "Division", endpoint: "division" },
    ]
  },
  {
    category: "Numbers",
    topics: [
      { name: "Rounding", icon: "rounding", topicKey: "Rounding" },
      { name: "Written Numbers", icon: "numberText", topicKey: "Numbers", endpoint: "written-numbers" },
    ]
  },
  {
    category: "Ratios & Rates",
    topics: [
      { name: "Ratios", icon: "ratio", topicKey: "Ratios", endpoint: "ratios" },
      { name: "Rates", icon: "gauge", topicKey: "Rates" },
      { name: "Unit Conversion", icon: "jug", topicKey: "Unit Conversion" },
    ]
  },
  {
    category: "Fractions",
    topics: [
      { name: "Fractions", icon: "pieBlue", topicKey: "Proper Fractions", endpoint: "fractions" },
      { name: "Improper Fractions", icon: "pieRed", topicKey: "Improper Fractions", endpoint: "improper-fractions" },
      { name: "Mixed Numbers", icon: "pieGreen", topicKey: "Mixed Numbers", endpoint: "mixed-numbers" },
    ]
  },
  {
    category: "Operations with Decimals",
    topics: [
      { name: "Decimal Addition", icon: "plusDecimal", topicKey: "Decimal Addition", endpoint: "decimal-addition" },
      { name: "Money", icon: "coin", topicKey: "Money", endpoint: "money" },
    ]
  },
  {
    category: "Time",
    topics: [
      { name: "Time Addition", icon: "clock", topicKey: "Time Addition", endpoint: "time-addition" },
    ]
  },
]

export default course