import React from 'react'
import { Card, Statistic } from 'antd'
import { exerciseLabels } from '../../engine/exerciseKeys'

const getBestExercise = userData => {
  const stats = userData.exercise_points || {}
  let best = { name: "N/A", points: "0" }

  Object.entries(stats).forEach(exercise => {
    if (exercise[1].points > best.points) {
      best.name = exerciseLabels[exercise[0]]
      best.points = exercise[1].points
    }
  })

  return best.name
}

const SummaryCard = props => (
  <Card style={{ margin: 15 }}>
    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
      <Statistic
        title="Account Level"
        style={{ fontFamily: "Source Sans Pro", fontSize: "1.2em", marginBottom: 15, marginRight: 150, whiteSpace: "nowrap" }}
        valueStyle={{ fontFamily: "Source Sans Pro" }}
        value={Math.ceil(props.user.total_points / 100) || 1}
      />
      <Statistic
        title="Total Score"
        style={{ fontFamily: "Source Sans Pro", fontSize: "1.2em", marginBottom: 15, marginRight: 150, whiteSpace: "nowrap" }}
        valueStyle={{ fontFamily: "Source Sans Pro" }}
        value={props.user.total_points}
      />
      <Statistic
        title="Best Exercise"
        style={{ fontFamily: "Source Sans Pro", marginBottom: 15, marginRight: 150, whiteSpace: "nowrap" }}
        valueStyle={{ fontFamily: "Source Sans Pro" }}
        value={getBestExercise(props.user)}
      />
    </div>
  </Card>
)

export default SummaryCard