import React from 'react'
import { BlockMath } from 'react-katex'
import styled from 'styled-components'
import { colors } from '../feedback'

const Container = styled.div`
  display: flex;
  flex-direction: ${({ direction = "row" }) => direction};
  flex-wrap: wrap;
`

const RadioButton = styled.button`
  background: ${({ result, selected }) => selected ? colors[result].background : "none"};
  border: 1px solid #ddd;
  border-color: ${({ result, selected }) => {
    if (selected) {
      return result !== "default" ? colors[result].border : "#aaa"
    }
    return "#ddd"
  }};
  border-width: ${({ selected }) => selected ? "2px" : "1px" };
  border-radius: 5px;
  margin: 0px 20px 20px 0px;
  max-width: ${({ size = "small" }) => `${sizes[size]}px`};
  padding: 10px;
  width: 100%;

  &:hover {
    border-color: #aaa;
    cursor: pointer;
    transition: border-color 0.2s;
  }
`

const Text = styled.p`
  color: ${({ disabled }) => disabled ? "#aaa" : "#333"};
  font-size: 18px;
  margin: 0px;
  text-align: ${({ size }) => size === "large" ? "left" : "center"};
`
const sizes = {
  small: 100,
  medium: 200,
  large: 400
}

/**
 * @param {string} direction Orientation of radio buttons [row, column]
 * @param {string} size   Size of radio button box [small, medium, large]
 * @param {array}  values Array of radio button values
 * @returns 
 */

const RadioInput = ({ direction = "row", disabled, enableKatex, onChange = () => { }, result = "default", selected, size = "small", values }) => (
  <Container direction={direction}>
    {values.map((val, i) => (
      <RadioButton
        disabled={disabled}
        key={i}
        onClick={() => onChange(val)}
        result={result}
        selected={selected === val}
        size={size}
      >
        { enableKatex
          ? <span style={{ color: disabled ? "#aaa" : "#333", fontSize: "1.5em"}}><BlockMath>{val}</BlockMath></span>
          : <Text disabled={disabled} size={size}>{val}</Text>
        }
      </RadioButton>
    ))}
  </Container>
)

export default RadioInput