import React from 'react'

const LongDivisionFormat = props => (
  <div>
    <span style={{ fontFamily: "Katex_Main", fontSize: "2em", borderRight: "1px black solid", paddingRight: 5, borderRadius: "0px 0px 8px 0px"}}>
        {props.divisor}
    </span>
    <span style={{ fontFamily: "Katex_Main", fontSize: "2em", borderTop: "1px black solid" }}>
        <span style={{ marginLeft: 5 }}>{props.dividend}</span>
    </span>
    <span style={{ fontFamily: "Katex_Main", fontSize: "2em", marginLeft: 10 }} >=</span>
  </div>
)

export default LongDivisionFormat